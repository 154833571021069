.powermail_form {
  $hover-delay: .1s;
  $transition-duration: .2s;

  display: flex;
  flex-direction: column;
  padding: 0 $gap*2;
  position: relative;
  transition: $transition-duration;

  *:focus {
    outline: none;
  }

  ::placeholder {
    color: $bg-color;
    opacity: .5;
  }

  fieldset {
    padding: 0;
    margin: 0;
    border: none;
    z-index: 2;
  }



  $textarea-size-initial: 5.5em;
  $textarea-grow: 7.5em;

  .powermail_fieldwrap {
    margin-bottom: $gap*2;
  }

  input[type="text"],
  input[type="email"],
  input[type="url"],
  input[type="tel"] {
    padding: $gap 0 $gap $gap*1.5;
    border: 0;
    border-radius: 0;
    background-color: $french-blue-color;
    color: $bg-color;
    width: 100%;
    box-sizing: border-box;
    z-index: 2;

    &:focus {
      outline: none;
    }
  }

  input[type="checkbox"],
  input[type="radio"] {
    appearance: none;
    outline: none;
    cursor: pointer;
    border-radius: 0;
    margin-right: 0.5em;

    &:before {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      content: "×";
      color: transparent;
      width: 1em;
      height: 1em;
      background: $french-blue-color;
    }

    &:checked:before {
      color: $bg-color;
    }

    &:focus {
      outline: none;
    }
  }

  input[type="radio"] {
    &:before {
      content: "·";
      border-radius: 50%;
    }

    &:focus {
      outline: none;
    }
  }

  select {
    position: relative;
    padding: $gap $gap*2 $gap $gap*1.5;
    width: 100%;
    box-sizing: border-box;
    appearance: none;
    border: 0;
    border-radius: 0;
    background: $french-blue-color url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='white'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
    background-position: calc(100% - 1em) calc(50% + 0.2em);
    background-size: .75em;
    color: #fff;

    &:focus {
      outline: none;
    }

    option {
      &[disabled] {
        color: transparentize($bg-color, .5);
      }
    }
  }

  textarea {
    display: block;
    min-width: 100%;
    max-width: 100%;
    min-height: $textarea-size-initial;
    height: $textarea-size-initial;
    padding: $gap $gap*1.5;
    border: 0;
    box-sizing: border-box;
    background-color: $french-blue-color;
    color: $bg-color;
    transition: height $transition-duration;
    border-radius: 0;

    &:focus {
      outline: none;
    }
  }

  button,
  input[type="submit"],
  input[type="button"],
  input[type="reset"]{
    width: auto;
    padding: $gap/2;
    box-sizing: border-box;
    float: none;
    border: 0;
    cursor: pointer;
    color: $bg-color;
    background-color: #000;
    font-weight: 600;
    text-transform: uppercase;
    transition: 0.2s;
    border-radius: 0;
    &:hover {
      background: $cobalt-color;
    }
  }

  label {
    cursor: pointer;
  }

  @include media(desktop) {
    .footer__contact & {
      &:focus-within {
        transition: $transition-duration;
        margin-top: -$textarea-grow;

        &:before {
          transform: translateY(-100%);
        }

        textarea {
          height: $textarea-size-initial + $textarea-grow;
        }
      }

      &:hover {
        transition-delay: $hover-delay;

        &:before {
          transition-delay: $hover-delay;
        }
        textarea {
          transition-delay: $hover-delay;
        }
      }
    }
  }

  @include media(tablet) {
    padding: 0;

    .footer__contact & {
      &:before {
        padding: $gap*2 0;
        position: relative;
        transform: none;
        z-index: 1;
      }
    }

    textarea {
      min-height: $textarea-size-initial + $textarea-grow;
    }
  }
}

/* Bewerbungs Formular  */


.tx-powermail form.layout1 {
  background-color: #eee;
  margin-bottom: 20px;
}

.powermail_fieldset {

  &.layout1, &.layout2 {
    margin: 25px 0;

    .powermail_legend {
      display: none;
    }

    .powermail_fieldwrap  {
      float: left;
      margin: 0 1em;

      label{
        font-weight: bold;
        color: #27509b;
      }
    }
  }

  &.layout1 {
    .powermail_fieldwrap  {
      width: 94%;
    }
  }
  &.layout2 {
    .powermail_fieldwrap  {
      width: 45%;
    }
  }
}

.powermail_form {

  .layout2, .layout1 {
    input[type="text"],
    input[type="email"],
    input[type="url"],
    input[type="tel"],
    input[type="file"],
    input[type="date"],
    textarea,
    select {
      background: #fff;
      border: 1px solid #436cb5;
      color: #436cb5;
      padding: 1rem 0 1rem 1.5rem;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 20px;
    }

    input[type="date"] {
      padding: 1rem 0 0.75rem 1.5rem;
    }

    input[type="file"] {
      padding: 0.75rem 0 0.9rem 0.5rem;
    }

  }

  input[type="reset"]{
    background-color: #436cb5;
  }
}

.powermail_fieldset.layout1 .powermail_fieldwrap.layout2 {
  width: 45%;
}

.powermail_fieldset.layout1 .powermail_fieldwrap.layout2 label{
  display: none;
}

.powermail_fieldset.layout2 .powermail_fieldwrap.layout1 {
  width: 100%;
}

.powermail_field h4 {
  margin-bottom: 20px;
}

