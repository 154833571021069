@import "vars";
@import "mixins";

/*==========================================================================
  basic helper classes
  ==========================================================================*/

.hidden,
[hidden] {
  display: none !important;
}
.invisible {
  visibility: hidden;
}
.lg-screen-only {
  @media (max-width : $layout-md-width) {
    display: none !important;
  }
}
.lg-screen-hidden {
  @media (min-width : ($layout-md-width+1)) {
    display: none !important;
  }
}
.md-screen-only {
  @media (min-width : ($layout-md-width+1)) {
    display: none !important;
  }
  @media (max-width : $layout-sm-width) {
    display: none !important;
  }
}
.sm-screen-only {
  @media (min-width : ($layout-sm-width+1)) {
    display: none !important;
  }
}
.sm-screen-hidden {
  @media (max-width : $layout-sm-width) {
    display: none !important;
  }
}
.print-only {
  @media screen { display: none !important; }
}
.print-hidden {
  @media print { display: none !important; }
}
.screen-reader-only,
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  clip: rect(0 0 0 0);
  overflow: hidden;
  border: none;
}

//////////////////////////////////////////////////////////////////////////

.clear,
.group:after,
.clear-after:after {
  clear: both;
}

.group:before,
.group:after,
.clear-after:after {
  display: table;
  content: "";
}

.fl-left,
.push-left { float: left; }
.fl-right,
.push-right { float: right; }

.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }
.text-justify { text-align: justify; }

.paragraph { margin-bottom: $gap; }

.gap { margin: $gap 0; }
.inner-gap { padding: $gap 0; }

.block { display: block !important; }

.main-font { font-family: $main-font !important; }
.title-font { font-family: $title-font !important; }

.test { outline: $border-size $highlight-color dotted; }
