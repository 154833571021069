.search-results {
  padding-bottom: $gap*4;
  &-entry {
    display: flex;
  }
  &-head {
    display: flex;
    justify-content: space-between;
  }
  &-bottom {
    float: right;
  }
  &-pages {
    list-style: none;
    .page {
      display: inline-block;
    }
  }
}

.page {
  .page-link {
    padding: 0 $gap/2;
    border-bottom: 1px solid $grey-color;
    a {
      color: $grey-color;
    }
  }
  .current {
    background: $grey-color;
    color: $bg-color;
  }
}

.result-number {
  left: 0;
  top: 0;
  font-size: 3em;
  font-weight: bold;
  line-height: 1em;
  width: $gap*3;
  text-align: center;
  color: $grey-color;
  z-index: 0;
}

.entry-headline {
  display: block;
  font-size: 1.5em;
  line-height: 1.2;
  margin-bottom: 3.75px;
  text-transform: uppercase;
  color: $text-color;
  border-bottom: 1px solid $grey-color;
}

.result-content {
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
}

.entry-text {
  box-sizing: border-box;
  padding-top: $gap;
}

.form-group {
  padding: $gap auto;
  &__search-input {
    width: $gap*15;
    height: $gap*2;
    margin-top: $gap*2;
    border: 0;
    border-bottom: 1px solid $grey-color;
    background-color: #b5b5b538;
    outline: none;
    &::placeholder {
      padding-left: $gap;
    }
  }
  &__search-submit {
    display: none;
  }
}