/*==========================================================================
  basic animation keyframes
  ==========================================================================*/

@keyframes slide-in-up {
  from { transform: translateY(20%); }
}

@keyframes slide-in-right {
  from { transform: translateX(-20%); }
}

@keyframes slide-in-down {
  from { transform: translateY(-20%); }
}

@keyframes slide-in-left {
  from { transform: translateX(20%); }
}

@keyframes fade-in {
  from { opacity: 0; }
}

@keyframes fade-out {
  to { opacity: 0; }
}

@keyframes fade-in-up {
  from { opacity: 0; transform: translateY(20%); }
}

@keyframes fade-in-right {
  from { opacity: 0; transform: translateX(-20%); }
}

@keyframes fade-in-down {
  from { opacity: 0; transform: translateY(-20%); }
}

@keyframes fade-in-left {
  from { opacity: 0; transform: translateX(20%); }
}

@keyframes fade-in-backward {
  from { opacity: 0; transform: scale(1.2); }
}

@keyframes fade-in-forward {
  from { opacity: 0; transform: scale(.8); }
}
