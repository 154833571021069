@import "vars";
@import "mixins";

/*==========================================================================
  grid system
  ==========================================================================*/

.grid {

  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

/* Farbiger Hintergunrd */


  .grey, .black, .blue {
    &.grid__col {
      padding: 0 0.5em !important;
    }
    .ce-bodytext, header {
      padding: 0.75em;
      }
    }

  .grey section {
      background: #e7e9eb;
    .more-link-btn {
      @include icon-btn('../assets/eye-icon-dark.svg');
    }

  }

   .blue section {
     background: #27509b;
     color: #fff;
      h1, h2, h3, h4, h5, h6, a{
        color: #fff;
      }
    }

   .black section {
     background: #000000;
     color: #fff;
       h1, h2, h3, h4, h5, h6, a{
         color: #fff;
       }
    }


  &:not(.grid--no-gap) {
    @include grid-spacing($grid-gap);
  }

  .no-flexbox & {
    display: block;
    @include clearfix();

    &:not(.grid--wrap) {
      .grid__col {
        clear: none;
      }
    }
  }

  &__col {

    width: 100%;
    vertical-align: top;
    min-height: 1px;
    box-sizing: border-box;

    .no-flexbox & {
      display: block;
      float: left;
    }

    // set up grid sizes
    @include grid-col-sizes('--', $grid-col-sizes);

    @media (max-width : $layout-lg-width) {
      @include grid-col-sizes('--lg-', $grid-col-sizes);
    }
    @media (max-width : $layout-md-width) {
      @include grid-col-sizes('--md-', $grid-col-sizes);
    }
    @media (max-width : $layout-sm-width) {
      @include grid-col-sizes('--sm-', $grid-col-sizes);
    }

  }

  &--wrap {
    // use this modifier to tell the grid it contains more than "a row" of columns (> 12/12).
    // it is used in case of .no-flexbox & { ... }.
    // use it only if problems with wrapped columns occure!
    // NOTE: this only affects legacy browsers (ie < 10) in no-flexbox mode.
    // not necessary for modern browsers.
  }

  &--flip {
    flex-direction: row-reverse;

    .no-flexbox & {
      > .grid__col {
        float: right;
      }
    }
  }

  &[class*="reverse"] {
    > .grid__col {
      &:not([class*="--sm-"]) {
        &, &:first-child {
          margin: $responsive-grid-margin 0 0;
        }
        &:last-child { margin-top: 0; }
      }
    }
  }
  &--sm-reverse {
    @media (max-width: $layout-sm-width) { flex-wrap: wrap-reverse; }
  }

  &--md-reverse {
    @media (max-width: $layout-md-width) { flex-wrap: wrap-reverse }
  }

  &--lg-reverse {
    @media (max-width: $layout-lg-width) { flex-wrap: wrap-reverse }
  }

  &--debug {
    .grid__col > * {
      $contrast-color: $highlight-color;
      background: rgba(red($contrast-color), green($contrast-color), blue($contrast-color), .15);
    }
  }

  &--responsive {
    @media (max-width : $grid-breakpoint) { @include grid-responsive(); }
  }

  &--sm-responsive {
    @media (max-width : $layout-sm-width) { @include grid-responsive(); }
  }

  &--md-responsive {
    @media (max-width : $layout-md-width) { @include grid-responsive(); }
  }

  &--lg-responsive {
    @media (max-width : $layout-lg-width) { @include grid-responsive(); }
  }

}
