.product-slider {
  padding: $gap*2 0;
  background: $very-light-blue-color;
  @include full-width();

  &__list {
    @include reset-list();
  }
  &__item {
    display: flex !important;
    padding: 0 $gap/4;
    box-sizing: border-box;
  }
  &__slide {
    position: relative;
    padding: $gap;
    text-align: center;
    background: $bg-color;
    transition: background-color .15s ease;

    &:hover {
      background: lighten($very-light-blue-color, 5%);
    }
  }
  &__image {
    margin-bottom: $gap;
    flex-grow: 1;
    object-fit: contain;
  }
  &__link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-indent: -99em;
  }

  .slick {
    $size: 2em;
    &-arrow {
      width: $size;
      height: $size*2;
      background: none;
      &:after {
        color: $bg-color;
        transform: rotate(315deg);
      }
    }
    &-prev {
      left: $gap/4;
      transform: translateX(calc(-100% + 1px)) translateY(-50%);
      @include corner($size, after) {
        left: $size/2;
        transform: rotate(315deg);
      }
    }
    &-next {
      right: $gap/4;
      transform: translateX(calc(100% - 1px)) translateY(-50%);
      @include corner($size) {
        right: $size/2;
        transform: rotate(135deg);
      }
    }

    &-track {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-items: stretch;
      justify-content: stretch;
      height: 100%;
      align-content: stretch;
    }

    &-track > .slick-slide {
      &,
      & > div,
      & > div > li,
      & > div > li > div {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        height: auto;
        align-items: center;
      }
    }
  }

  @include media(mobile) {
    box-sizing: border-box;

    &__item {
      padding: 0;
    }
  }
  
  @media (max-width: $layout-xs-width) {
    padding: $gap*2;
  }
}

@supports (-ms-ime-align: auto) {
  .product-slider{
    &__image {
      align-self: center;
    }
    .slick {
      &-track > .slick-slide {
        &,
        & > div,
        & > div > li,
        & > div > li > div {
          align-items: stretch;
        }
      }
    }
  }
}