.meta-bar {
  padding: $gap 0;
  background: $very-light-blue-color;
  @include full-width();

  &__list {
    display: flex;
    @include reset-list();
  }

  &__item {
    & + & {
      margin-left: $gap*2;
    }
    &:last-child {
      flex-grow: 1;
    }
  }

  @include media(mobile) {
    &__list {
      flex-direction: column;
    }
    &__item {
      & + & {
        margin-left: 0;
        margin-top: $gap;
      }
    }
  }
}
