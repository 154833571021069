#faudi-clock {
  margin: 4rem;
  color: #333;
  text-align: center;

  @media (max-width: $small-desktop-width) {
    margin: 1rem;
  }

  @media (max-width: $layout-xs-width) {
    margin: 0;
  }

  h2 {
    border-bottom: 5px solid #27509b;
    padding: 5px 0;
    letter-spacing: 3px;
    font-size: 4em;

    @media (max-width: $small-desktop-width) {
      font-size: 4em;
      margin: .5em 0;
    }

    @media (max-width: $layout-xs-width) {
      margin: 0;
    }
  }

  #clock-numbers{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
  }

  &.boxed{
    margin: 0;
    margin-top: 25%;
    .cd-box{
      width: 20%;
      @media (max-width: $small-desktop-width) {
        width: 30%;
      }

      @media (max-width: $layout-xs-width) {
        width: 40%;
      }
    }
  }

  .cd-box {
    display: flex;
    flex-flow: column nowrap;
    width: 12%;
    flex-grow: 1;
    box-shadow: 5px 6px 9px 1px rgba(53, 53, 53, 0.5);
    margin: 15px 7px;

    @media (max-width: $small-desktop-width) {
      width: 30%;
    }

    @media (max-width: $layout-xs-width) {
      width: 48%;
    }

    .numbers{
      background: #FFFFFF;
      font-size: 2.5rem;
      margin-top: 50px;
      line-height: 2.5rem;
      font-weight: 700;
    }


    .timeString{
      background: #27509b;
      color: #FFFFFF;
      font-size: 1.5rem;
      padding: 10px;
      font-weight: 600;
    }
  }
}
