.collapsible {
  &__list {
    @include reset-list();
  }

  & + & {
    margin-top: $gap/2;
  }

  &__item {
    position: relative;

    & + & {
      margin-top: $gap/2;
    }

    &:before,
    &:after {
      display: block;
      position: absolute;
      content: "";
      pointer-events: none;
      right: 0.75rem;
      top: 1.4rem;
      width: 1.5rem;
      height: 4px;
      background: $bg-color;
      z-index: 1;
      transform: rotate(0deg);
      transition: transform .2s;
    }

    &:after {
      transform: rotate(90deg);
    }

    &--active {
      &:before {
        transform: rotate(180deg);
      }
      &:after {
        transform: rotate(180deg);
      }

      .collapsible__head {
        background: $cobalt-color;

        &:hover {
          background: darken($cobalt-color, 5%);
        }
      }
    }
  }
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    cursor: pointer;
    background: $faudi-color;
    @include text-color($bg-color);
    transition: 0.2s;

    h2 {
      line-height: 3em;
    }

    &:hover {
      background: $cobalt-color;
    }

    &:after {
      display: block;
      position: absolute;
      content: "";
      pointer-events: none;
      right: 0;
      top: 0;
      width: calc(3rem - 1px);
      height: calc(3rem - 1px);
      background: $text-color;
      z-index: 0;
    }
  }
  &__body {}
  &__title {
    padding: 0 $gap*2;
    font-size: .85em;
  }
  &__preview {
    width: 15em;
    overflow: hidden;

    img {
      display: block;
      animation: slide-zoom 10s ease-in-out infinite alternate;
    }
  }

  @include media(desktop) {
    &__item {
      &:after {
        font-size: 2em;
      }
    }
  }

  @include media(mobile) {
    &__title {
      padding: $gap;
      font-size: .4em;
    }
    &__preview {
      display: none;
    }
  }

  @keyframes slide-zoom {
    to { transform: scale(1.2); }
  }

  & & &__head {
    overflow: hidden;
    background-color: $light-blue-color;
    height: calc(3rem - 1px);
    h2 {
      font-weight: lighter;
      font-size: $gap*1.5;
    }
      &:after {
        height: calc(3rem - 1px);
        background-color: $dark-blue-color;
      }
  }
}
