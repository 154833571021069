.quick-bar {
  display: flex;
  justify-content: flex-end;
  padding: $gap/2 $gap;
  background: $very-light-blue-color;

  .footer & {
    background: none;
    padding: $gap/2 0;
  }
  &__contacts {
    display: flex;
    & a{
      margin: 2px 10px;
    }
  }
  &__contact {
    width: 2em;
    transition: background-color .15s ease;
    background: $grey-color;
    & + & {
      margin-left: $gap/2;
    }
    &:hover {
      background: $text-color;
    }
    img { display: block; }
  }

  &__langs {
    display: flex;
    align-items: center;
    margin-left: $gap;
  }
  &__lang {
    font-weight: 600;
    opacity: .4;
    color: $text-color;
    & + & {
      margin-left: $gap/2;
      padding-left: $gap/2;
      border-left: .1em $grey-color solid;
    }
    &--right {
      padding-right: 0.7em;
    }
    &--active {
      opacity: 1;
    }
  }

  @include media(desktop) {
    padding-left: $gap*3;
    padding-right: $gap*3;
  }
}
