@import "vars";

/*==========================================================================
  typography + basic html styles
  ==========================================================================*/

html { font-size: $default-font-size; }

body {
  position: relative;

  font-family: $main-font;
  font-weight: $main-font-weight;
  line-height: $default-line-height;

  color: $text-color;
  background: $bg-color;

  &:before {
    content: "lg";
    display: none; // prevent from displaying
  }
  @media (max-width: $layout-md-width) {
    &:before { content: "md"; }
  }
  @media (max-width: $layout-sm-width) {
    &:before { content: "sm"; }
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $title-font;
  font-size: 2.0em;
  font-weight: $title-font-weight;
  line-height: $default-line-height * 0.72;
  color: $title-color;
}
h1, .h1 { font-size: 4em }
h2, .h2 { font-size: 3em }
h3, .h3 { font-size: 2em }
h4, .h4 { font-size: 1.5em }
h5, h6, .h5 { font-size: 1.0em }

img, svg, video {
  max-width: 100%;
  height: auto;
}

blockquote {
  margin: 0;
}

h1, h2, h3, h4, h5, h6,
header,
p,
hr,
ul, ol,
blockquote {
  margin: 0 0 $gap;
  &:last-child { margin-bottom: 0; }
}

b, strong { font-weight: bold; }

hr {
  height: $border-size;
  border: none;
  background: mix($bg-color, $text-color, 80%);
}

a {
  color: $link-color;
  text-decoration: none;
  cursor: pointer;

  &.selected,
  &:hover {
    color: $highlight-color;
    text-decoration: underline;
  }
}

table.table {
  width: 100%;
  td, th { padding: $gap/4 $gap/2; }
  thead {
    border-bottom: $border-size darken($bg-color, 10%) solid;
    th { text-align: left; }
  }
  tbody {
    tr {
      &:nth-child(2n) {
        & { background: darken($bg-color, 5%); }
      }
      &:hover {
        & { background: darken($bg-color, 10%); }
      }
    }
  }
  tfoot {
    border-top: $border-size darken($bg-color, 10%) solid;
  }
}
