[data-in-view="false"] {
  opacity: 0;
}
[data-in-view="true"] {
  animation: fade-in-up ease 0.5s both;

  & > * {
    animation: fade-in-up ease 1s both;
  }
}
