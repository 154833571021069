.slick {
  &-arrow {
    --arrow-size: 2em;

    display: block;
    position: absolute;
    top: 50%;
    width: var(--arrow-size);
    height: var(--arrow-size);
    transform: translateY(-50%);
    overflow: hidden;
    text-indent: -99em;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent no-repeat center;
    z-index: 1;
  }

  &-prev {
    left: $gap*2;
    background-image: url('../assets/slide-left.svg');
  }
  &-next {
    right: $gap*2;
    background-image: url('../assets/slide-right.svg');
  }

  &-dots {
    display: flex;
    position: absolute;
    left: 50%;
    bottom: $gap*2;
    transform: translate(-50%);
    @include reset-list();

    button {
      margin: 0 $gap/2;
      padding: 0;
      width: 1em;
      height: 1em;
      overflow: hidden;
      text-indent: -99em;
      cursor: pointer;
      color: transparent;
      border-radius: 50%;
      border: 2px solid $bg-color;
      transition: background-color .5s ease;
      background: $grey-color;
    }
    .slick-active button {
      background: $faudi-red-color;
    }
  }

  @include media(desktop) {
    &-arrow {
      --arrow-size: 3.5em;
    }
  }

  @include media(mobile) {
    &-arrow {
      display: none;
    }
  }
}
