.teaser {
  @include full-width();

  &,
  &--light {
    // defaults
    background: $lighter-grey-color;
  }

  &--dark {
    @include text-color($bg-color);
    background: $text-color;
  }

  &__image {
    position: relative;
    display: block;
    margin: 0 auto;

    > * {
      display: block;
      margin: 0 auto;
    }

    &--blur {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      overflow: hidden;

      > * {
        object-fit: cover;
        width: 100%;
        filter: blur(10px);
        transform: scale(1.1);
      }
    }

    &--fullsize {
      > * {
        margin: 0;
        width: 100vw;
        max-width: none;
        object-fit: contain;
      }
    }
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 2;

    @media (min-width: $page-width) {
      left: calc((100vw - #{$page-width})/2);
    }
  }

  &__title {
    padding-top: $gap;

    h2 {
      font-weight: bold;
    }
  }

  &__actions {
    @include reset-list();
    z-index: 2;
    pointer-events: all;
  }

  &__link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-indent: -99rem;
    z-index: 1;
  }

  @include media(desktop) {
    &__title {
      padding: $gap*4 0 $gap;
      font-size: 1.5em;
    }
  }

  @include media(mobile) {
    &__title {
      font-size: .75em;
    }
  }
}
