.download-btn {
  @include icon-btn('../assets/download-icon.svg');
  background-color: $grey-color !important;
  margin-top: 0.5em;
  padding-bottom: 0;
  padding-top: 0;

  &:hover {
    &:before {
      animation: none;
    }
  }
}
