.variation {
  padding: $gap*4 0;
  box-sizing: border-box;
  @include full-width();

  &__content {
    padding: $gap*2;
  }

  &,
  &--dark {
    background: $very-light-blue-color;
    .variation__content { background: $bg-color; }
  }
  &--light {
    background: $bg-color;
    .variation__content { background: $very-light-blue-color; }
  }
}
