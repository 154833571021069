.news-list {
  padding: $gap*4 0;
  background: $very-light-blue-color;
  @include full-width();
  box-sizing: border-box;

  @media (max-width: $small-desktop-width) {
    padding: $gap*4.5;
  }

  @include media(tablet) {
    padding: $gap*2.5;
  }

  @media (max-width: $layout-xs-width) {
    padding: $gap;
  }

  &__item {
    & + & {
      margin-top: $gap*4;
    }
    @include media(desktop) {
      max-width: $page-width;
      margin: 0 auto;
    }
  }
}
