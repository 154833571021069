.gallery {
  position: relative;

  &__list {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 15em 15em;
    grid-template-areas:
      "small-1 large"
      "small-2 large";
    @include reset-list();
  }
  &__item {
    position: relative;
    overflow: hidden;
    &:nth-child(1n) { grid-area: small-1; }
    &:nth-child(2n) { grid-area: small-2; }
    &:nth-child(3n) { grid-area: large; }
    * { height: 100%; width: 100%;}
    img { width: 100%; object-fit: cover; }
  }
  &__more {
    position: absolute;
    right: $gap*2;
    bottom: $gap*2;
    padding-left: 0.75em;
    background: transparentize($text-color, 0.5);
    @include text-color($bg-color);
    z-index: 1;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 1.5em;
      padding: 0 0.75em;
      background: $text-color;
      z-index: -1;
    }
  }

  @include media(mobile) {
    &__list {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 8em 15em;
      grid-template-areas:
        "small-1 small-2"
        "large large";
    }
    &__more {
      right: $gap;
      bottom: $gap;
    }
  }
}
