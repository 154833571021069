.solutions {
  display: flex;
  flex-direction: column-reverse;
  

  &__problems {
    padding: $gap*5 0 $gap*3;
  }

  &__problem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    h3 {
      font-weight: 500;
    }
    
    img:first-of-type {
      display: block;
      border: 1.5px solid $grey-color;
      border-radius: 50%;
      width: 10em;
      height: 10em;
      object-fit: cover;
      margin: $gap 0;
    }
  }

  &__answers {
    padding: $gap*3 0;
    box-sizing: border-box;
    background: $faudi-color;
    @include text-color($bg-color);
    @include full-width();
    @include corner(2em) {
      color: $faudi-color;
      left: 50%;
      bottom: 1px;
      transform: translate(-50%, 50%) rotate(225deg);
      line-height: 1.15;
    };
    & p {
      font-size: 2rem;
      @media (max-width: $layout-sm-width) {
        &__answers {
         text-align: center;
        }
      }
    }
  }

  @include media(desktop) {
    &__answers {
      font-size: 1.5em;
      line-height: 1.5;
    }
  }
  @media (max-width: $layout-sm-width) {
    &__answers {
     text-align: center;
    }
  }
  @include media(tablet) {
    &__problem {
      img:first-of-type {
        width: 10em;
        height: 10em;
      }
    }
  }

  @include media(mobile) {
    &__problem {
      align-items: flex-start;
      min-height: 7rem;
      padding-left: 50%;
      border-bottom: none;
      position: relative;
      text-align: left;

      & > a,
      & > img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-150%, -50%);
      }

      img:first-of-type {
        width: 7em;
        height: 7em;
      }
    }
    &__answers p {
      line-height: initial;
    }
  }
}
