.header {
  --header-height: 7.5rem;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  height: var(--header-height);
  box-sizing: border-box;
  padding: $gap;
  margin: 0;
  border-bottom: 2px solid $faudi-color;
  background: $bg-color;
  z-index: 5;
  transition: 0.3s height;

  &__logo {
    display: block;
    width: 10em;
    transition: 0.3s width;
    img { display: block; }
  }

  @include media(desktop) {
    padding: $gap*1.5 $gap*3;
  }
  @include media(tablet) {
    --header-height: 4rem;
    &__logo {
      width: 6em;
    }
  }

  .sticky-wrapper.is-sticky & {
    --header-height: 4rem;

    &__logo {
      width: 5em;
    }
  }
}

.sticky-wrapper {
  height: auto !important;
}