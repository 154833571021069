.plus-btn {
  @include icon-btn("../assets/plus-icon.svg");
  padding-right: $gap;
  &,
  &--light {
    &:before {
      background-size: 1.5em;
      background-position: left center;
      height: 2em;
    }
  }
}
