$fonts: '../assets/fonts/';

$main-font: "Source Sans Pro", Arial, Helvetica, sans-serif;
$title-font: $main-font;

$title-font-weight: 600;

// colors according to style guide
$faudi-color: #27509b;
$cobalt-color: #1f4384;
$cool-blue-color: #4c74bc;
$french-blue-color: #436cb5;
$dark-slate-blue-color: #162c55;
$light-blue-color: #2E74C0;
$dark-blue-color: #004f9e;
$very-light-blue-color: #e7e9eb;
$grey-color: #b5b5b5;
$lighter-grey-color: #dfe0e2;
$dark-grey-color: #888;
$darker-grey-color: #6c6c6c;
$faudi-red-color: #c20e1a;

$theme-color: $faudi-color;
$text-color: #000;
$title-color: $text-color;

$page-width: 1000px;
$page-min-width: 240px;

$layout-sm-width: 640px;
$layout-xs-width: 375px;
$small-desktop-width: 1200px;
