.slider {

  max-height: 642px;

  button:focus {
    box-shadow: none;
  }

  &__item {
    display: block;
    position: relative;
    padding: 0;
    margin: 0;
  }

  &__caption {
    position: absolute;
    width: 50%;
    left: 20%;
    top: 50%;
    transform: translate(-20%, -50%);
    text-align: left;
    font-size: 1.5em;
    font-weight:400;
    z-index: 1;

    &.light{
      * { color: #000;}

      .btn{
          padding: 0.33rem 0.66rem 0.33rem;
        &:after{
          background: url("../assets/icon-arrow.svg") right center no-repeat;
        }
      }
    }

    &.dark {
      * { color: $bg-color; }
    }

    p {
      line-height: 1.25;
    }
  }

  .btn{
    font-size:0.75em;
    font-weight: 600;
    text-transform: uppercase;

    &.dark {
      color: $bg-color;
    }
    &.light {
      color: #000;
    }

    &:after{
      content: '';
      display: inline-block;
      position: relative;
      vertical-align: middle;
      width:2em;
      height:1em;
      margin-left: 0.6em;
      transform: translateY(-0.05em);
      transform-origin: center bottom;
    }

    &.dark:after {
      background: url("../assets/icon-arrow-light.svg") right center no-repeat;
    }

    &.light:after {
      background: url("../assets/icon-arrow.svg") right center no-repeat;
    }


  }

  .slick {
    &-slide {
      margin-bottom: -6px;
      // fix inline-block that gets set by slick for some annoying reason

      div, figure, img, video { display: block !important; }
      img {
        object-fit: cover;
      }
      video { width: 100%; }
    }
    &-active {
      .animation {
        img {
          animation: slide-zoom 10s ease-in alternate forwards;
        }
      }
    }
  }

  .slick-arrow {
    top: 55%;
  }

  .slick-prev {
    left: 1em;
  }

  .slick-next {
    right: 1em;
  }

  @include media(mobile) {
    &__caption {
      font-size: .75em;
    }
  }
}

@keyframes slide-zoom {
  to { transform: scale(1.1); }
}


.slider{
  &.fullsize {
    padding: 0;
    position: relative;
    left: 50%;
    right: 50%;
    width: 100vw;
    margin-left: -50vw;
    margin-right: -50vw;
    overflow: hidden;

    .slick {
      &-slide {
        img {
          object-fit: cover;
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 820px) {

  .slider__caption {
    width: 50%;
  }
  .slider.fullsize {
    max-height: 460px;

    .slick-slide img {
      max-height: 460px;
    }
  }
  .slider .slick-arrow {
    display: none !important;
  }

  .slider .btn {
    padding: 0 0.66rem;
  }
}


@media (max-width: 390px) {
  .slider__caption {
    width: 80%;
    font-size: 14px;
    top: 10%;
    transform: translate(-20%, -10%);
  }
  .slider.fullsize {
    max-height: 360px;

    .slick-slide img {
      max-height: 360px;
    }
  }
}

