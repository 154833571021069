@charset "UTF-8";
@keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    transform: translate3d(0, -0.05em, 0); }
  40%,
  43% {
    transform: translate3d(0, -1em, 0); }
  70% {
    transform: translate3d(0, -0.5em, 0); }
  90% {
    transform: translate3d(0, -0.125em, 0); } }

/*==========================================================================
  mixins
  ==========================================================================*/
/*==========================================================================
  basic animation keyframes
  ==========================================================================*/
@keyframes slide-in-up {
  from {
    transform: translateY(20%); } }

@keyframes slide-in-right {
  from {
    transform: translateX(-20%); } }

@keyframes slide-in-down {
  from {
    transform: translateY(-20%); } }

@keyframes slide-in-left {
  from {
    transform: translateX(20%); } }

@keyframes fade-in {
  from {
    opacity: 0; } }

@keyframes fade-out {
  to {
    opacity: 0; } }

@keyframes fade-in-up {
  from {
    opacity: 0;
    transform: translateY(20%); } }

@keyframes fade-in-right {
  from {
    opacity: 0;
    transform: translateX(-20%); } }

@keyframes fade-in-down {
  from {
    opacity: 0;
    transform: translateY(-20%); } }

@keyframes fade-in-left {
  from {
    opacity: 0;
    transform: translateX(20%); } }

@keyframes fade-in-backward {
  from {
    opacity: 0;
    transform: scale(1.2); } }

@keyframes fade-in-forward {
  from {
    opacity: 0;
    transform: scale(0.8); } }

/*==========================================================================
  typography + basic html styles
  ==========================================================================*/
html {
  font-size: 16px; }

body {
  position: relative;
  font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
  font-weight: normal;
  line-height: 1.5em;
  color: #000;
  background: #FFF; }
  body:before {
    content: "lg";
    display: none; }
  @media (max-width: 959px) {
    body:before {
      content: "md"; } }
  @media (max-width: 640px) {
    body:before {
      content: "sm"; } }

h1, h2, h3, h4, h5, h6 {
  font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
  font-size: 2.0em;
  font-weight: 600;
  line-height: 1.08em;
  color: #000; }

h1, .h1 {
  font-size: 4em; }

h2, .h2 {
  font-size: 3em; }

h3, .h3 {
  font-size: 2em; }

h4, .h4 {
  font-size: 1.5em; }

h5, h6, .h5 {
  font-size: 1.0em; }

img, svg, video {
  max-width: 100%;
  height: auto; }

blockquote {
  margin: 0; }

h1, h2, h3, h4, h5, h6,
header,
p,
hr,
ul, ol,
blockquote {
  margin: 0 0 1rem; }
  h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child,
  header:last-child,
  p:last-child,
  hr:last-child,
  ul:last-child, ol:last-child,
  blockquote:last-child {
    margin-bottom: 0; }

b, strong {
  font-weight: bold; }

hr {
  height: 2px;
  border: none;
  background: #cccccc; }

a {
  color: #27509b;
  text-decoration: none;
  cursor: pointer; }
  a.selected, a:hover {
    color: #12264a;
    text-decoration: underline; }

table.table {
  width: 100%; }
  table.table td, table.table th {
    padding: 0.25rem 0.5rem; }
  table.table thead {
    border-bottom: 2px #e6e6e6 solid; }
    table.table thead th {
      text-align: left; }
  table.table tbody tr:nth-child(2n) {
    background: #f2f2f2; }
  table.table tbody tr:hover {
    background: #e6e6e6; }
  table.table tfoot {
    border-top: 2px #e6e6e6 solid; }

/*==========================================================================
  mixins
  ==========================================================================*/
/*==========================================================================
  basic helper classes
  ==========================================================================*/
.hidden,
[hidden] {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media (max-width: 959px) {
  .lg-screen-only {
    display: none !important; } }

@media (min-width: 960px) {
  .lg-screen-hidden {
    display: none !important; } }

@media (min-width: 960px) {
  .md-screen-only {
    display: none !important; } }

@media (max-width: 640px) {
  .md-screen-only {
    display: none !important; } }

@media (min-width: 641px) {
  .sm-screen-only {
    display: none !important; } }

@media (max-width: 640px) {
  .sm-screen-hidden {
    display: none !important; } }

@media screen {
  .print-only {
    display: none !important; } }

@media print {
  .print-hidden {
    display: none !important; } }

.screen-reader-only,
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  clip: rect(0 0 0 0);
  overflow: hidden;
  border: none; }

.clear,
.group:after,
.clear-after:after {
  clear: both; }

.group:before,
.group:after,
.clear-after:after {
  display: table;
  content: ""; }

.fl-left,
.push-left {
  float: left; }

.fl-right,
.push-right {
  float: right; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-justify {
  text-align: justify; }

.paragraph {
  margin-bottom: 1rem; }

.gap {
  margin: 1rem 0; }

.inner-gap {
  padding: 1rem 0; }

.block {
  display: block !important; }

.main-font {
  font-family: "Source Sans Pro", Arial, Helvetica, sans-serif !important; }

.title-font {
  font-family: "Source Sans Pro", Arial, Helvetica, sans-serif !important; }

.test {
  outline: 2px #12264a dotted; }

/*==========================================================================
  mixins
  ==========================================================================*/
/*==========================================================================
  grid system
  ==========================================================================*/
.grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  /* Farbiger Hintergunrd */ }
  .grid .grey.grid__col, .grid .black.grid__col, .grid .blue.grid__col {
    padding: 0 0.5em !important; }
  .grid .grey .ce-bodytext, .grid .grey header, .grid .black .ce-bodytext, .grid .black header, .grid .blue .ce-bodytext, .grid .blue header {
    padding: 0.75em; }
  .grid .grey section {
    background: #e7e9eb; }
    .grid .grey section .more-link-btn {
      display: inline-block;
      padding: .5em .9em .5em 0;
      text-transform: uppercase;
      font-weight: 600;
      color: #000;
      line-height: 2em; }
      .grid .grey section .more-link-btn:before {
        content: '';
        display: inline-block;
        position: relative;
        width: 2em;
        height: 2em;
        vertical-align: middle;
        margin-right: 0.6em;
        transform: translateY(-0.05em);
        transform-origin: center bottom; }
      .grid .grey section .more-link-btn:hover:before {
        animation-name: bounce;
        animation-duration: 0.5s; }
      .grid .grey section .more-link-btn, .grid .grey section .more-link-btn--light {
        color: #000; }
        .grid .grey section .more-link-btn:before, .grid .grey section .more-link-btn--light:before {
          background: url("../assets/eye-icon-dark.svg") left center no-repeat; }
      .grid .grey section .more-link-btn--dark {
        color: #FFF; }
        .grid .grey section .more-link-btn--dark:before {
          background: url("../assets/eye-icon-dark.svg") left center no-repeat; }
  .grid .blue section {
    background: #27509b;
    color: #fff; }
    .grid .blue section h1, .grid .blue section h2, .grid .blue section h3, .grid .blue section h4, .grid .blue section h5, .grid .blue section h6, .grid .blue section a {
      color: #fff; }
  .grid .black section {
    background: #000000;
    color: #fff; }
    .grid .black section h1, .grid .black section h2, .grid .black section h3, .grid .black section h4, .grid .black section h5, .grid .black section h6, .grid .black section a {
      color: #fff; }
  .grid:not(.grid--no-gap) {
    margin-left: -1rem;
    margin-right: -1rem; }
    .grid:not(.grid--no-gap) > .grid__col {
      padding: 0 1rem; }
  .no-flexbox .grid {
    display: block; }
    .no-flexbox .grid:before, .no-flexbox .grid:after {
      display: table;
      content: "";
      clear: both; }
    .no-flexbox .grid:not(.grid--wrap) .grid__col {
      clear: none; }
  .grid__col {
    width: 100%;
    vertical-align: top;
    min-height: 1px;
    box-sizing: border-box; }
    .no-flexbox .grid__col {
      display: block;
      float: left; }
    .grid__col--1 {
      max-width: 8.33333%;
      flex: 0 0 8.33333%; }
      .no-flexbox .grid__col--1 {
        width: 8.33333%;
        max-width: none; }
        .no-flexbox .grid__col--1:nth-child(12n + 13) {
          clear: both; }
    .grid__col--2 {
      max-width: 16.66667%;
      flex: 0 0 16.66667%; }
      .no-flexbox .grid__col--2 {
        width: 16.66667%;
        max-width: none; }
        .no-flexbox .grid__col--2:nth-child(6n + 7) {
          clear: both; }
    .grid__col--3 {
      max-width: 25%;
      flex: 0 0 25%; }
      .no-flexbox .grid__col--3 {
        width: 25%;
        max-width: none; }
        .no-flexbox .grid__col--3:nth-child(4n + 5) {
          clear: both; }
    .grid__col--4 {
      max-width: 33.33333%;
      flex: 0 0 33.33333%; }
      .no-flexbox .grid__col--4 {
        width: 33.33333%;
        max-width: none; }
        .no-flexbox .grid__col--4:nth-child(3n + 4) {
          clear: both; }
    .grid__col--5 {
      max-width: 41.66667%;
      flex: 0 0 41.66667%; }
      .no-flexbox .grid__col--5 {
        width: 41.66667%;
        max-width: none; }
        .no-flexbox .grid__col--5:nth-child(2n + 3) {
          clear: both; }
    .grid__col--6 {
      max-width: 50%;
      flex: 0 0 50%; }
      .no-flexbox .grid__col--6 {
        width: 50%;
        max-width: none; }
        .no-flexbox .grid__col--6:nth-child(2n + 3) {
          clear: both; }
    .grid__col--7 {
      max-width: 58.33333%;
      flex: 0 0 58.33333%; }
      .no-flexbox .grid__col--7 {
        width: 58.33333%;
        max-width: none; }
        .no-flexbox .grid__col--7:nth-child(1n + 2) {
          clear: both; }
    .grid__col--8 {
      max-width: 66.66667%;
      flex: 0 0 66.66667%; }
      .no-flexbox .grid__col--8 {
        width: 66.66667%;
        max-width: none; }
        .no-flexbox .grid__col--8:nth-child(1n + 2) {
          clear: both; }
    .grid__col--9 {
      max-width: 75%;
      flex: 0 0 75%; }
      .no-flexbox .grid__col--9 {
        width: 75%;
        max-width: none; }
        .no-flexbox .grid__col--9:nth-child(1n + 2) {
          clear: both; }
    .grid__col--10 {
      max-width: 83.33333%;
      flex: 0 0 83.33333%; }
      .no-flexbox .grid__col--10 {
        width: 83.33333%;
        max-width: none; }
        .no-flexbox .grid__col--10:nth-child(1n + 2) {
          clear: both; }
    .grid__col--11 {
      max-width: 91.66667%;
      flex: 0 0 91.66667%; }
      .no-flexbox .grid__col--11 {
        width: 91.66667%;
        max-width: none; }
        .no-flexbox .grid__col--11:nth-child(1n + 2) {
          clear: both; }
    .grid__col--12 {
      max-width: 100%;
      flex: 0 0 100%; }
      .no-flexbox .grid__col--12 {
        width: 100%;
        max-width: none; }
        .no-flexbox .grid__col--12:nth-child(1n + 2) {
          clear: both; }
    @media (max-width: 999px) {
      .grid__col--lg-1 {
        max-width: 8.33333%;
        flex: 0 0 8.33333%; }
        .no-flexbox .grid__col--lg-1 {
          width: 8.33333%;
          max-width: none; }
          .no-flexbox .grid__col--lg-1:nth-child(12n + 13) {
            clear: both; }
      .grid__col--lg-2 {
        max-width: 16.66667%;
        flex: 0 0 16.66667%; }
        .no-flexbox .grid__col--lg-2 {
          width: 16.66667%;
          max-width: none; }
          .no-flexbox .grid__col--lg-2:nth-child(6n + 7) {
            clear: both; }
      .grid__col--lg-3 {
        max-width: 25%;
        flex: 0 0 25%; }
        .no-flexbox .grid__col--lg-3 {
          width: 25%;
          max-width: none; }
          .no-flexbox .grid__col--lg-3:nth-child(4n + 5) {
            clear: both; }
      .grid__col--lg-4 {
        max-width: 33.33333%;
        flex: 0 0 33.33333%; }
        .no-flexbox .grid__col--lg-4 {
          width: 33.33333%;
          max-width: none; }
          .no-flexbox .grid__col--lg-4:nth-child(3n + 4) {
            clear: both; }
      .grid__col--lg-5 {
        max-width: 41.66667%;
        flex: 0 0 41.66667%; }
        .no-flexbox .grid__col--lg-5 {
          width: 41.66667%;
          max-width: none; }
          .no-flexbox .grid__col--lg-5:nth-child(2n + 3) {
            clear: both; }
      .grid__col--lg-6 {
        max-width: 50%;
        flex: 0 0 50%; }
        .no-flexbox .grid__col--lg-6 {
          width: 50%;
          max-width: none; }
          .no-flexbox .grid__col--lg-6:nth-child(2n + 3) {
            clear: both; }
      .grid__col--lg-7 {
        max-width: 58.33333%;
        flex: 0 0 58.33333%; }
        .no-flexbox .grid__col--lg-7 {
          width: 58.33333%;
          max-width: none; }
          .no-flexbox .grid__col--lg-7:nth-child(1n + 2) {
            clear: both; }
      .grid__col--lg-8 {
        max-width: 66.66667%;
        flex: 0 0 66.66667%; }
        .no-flexbox .grid__col--lg-8 {
          width: 66.66667%;
          max-width: none; }
          .no-flexbox .grid__col--lg-8:nth-child(1n + 2) {
            clear: both; }
      .grid__col--lg-9 {
        max-width: 75%;
        flex: 0 0 75%; }
        .no-flexbox .grid__col--lg-9 {
          width: 75%;
          max-width: none; }
          .no-flexbox .grid__col--lg-9:nth-child(1n + 2) {
            clear: both; }
      .grid__col--lg-10 {
        max-width: 83.33333%;
        flex: 0 0 83.33333%; }
        .no-flexbox .grid__col--lg-10 {
          width: 83.33333%;
          max-width: none; }
          .no-flexbox .grid__col--lg-10:nth-child(1n + 2) {
            clear: both; }
      .grid__col--lg-11 {
        max-width: 91.66667%;
        flex: 0 0 91.66667%; }
        .no-flexbox .grid__col--lg-11 {
          width: 91.66667%;
          max-width: none; }
          .no-flexbox .grid__col--lg-11:nth-child(1n + 2) {
            clear: both; }
      .grid__col--lg-12 {
        max-width: 100%;
        flex: 0 0 100%; }
        .no-flexbox .grid__col--lg-12 {
          width: 100%;
          max-width: none; }
          .no-flexbox .grid__col--lg-12:nth-child(1n + 2) {
            clear: both; } }
    @media (max-width: 959px) {
      .grid__col--md-1 {
        max-width: 8.33333%;
        flex: 0 0 8.33333%; }
        .no-flexbox .grid__col--md-1 {
          width: 8.33333%;
          max-width: none; }
          .no-flexbox .grid__col--md-1:nth-child(12n + 13) {
            clear: both; }
      .grid__col--md-2 {
        max-width: 16.66667%;
        flex: 0 0 16.66667%; }
        .no-flexbox .grid__col--md-2 {
          width: 16.66667%;
          max-width: none; }
          .no-flexbox .grid__col--md-2:nth-child(6n + 7) {
            clear: both; }
      .grid__col--md-3 {
        max-width: 25%;
        flex: 0 0 25%; }
        .no-flexbox .grid__col--md-3 {
          width: 25%;
          max-width: none; }
          .no-flexbox .grid__col--md-3:nth-child(4n + 5) {
            clear: both; }
      .grid__col--md-4 {
        max-width: 33.33333%;
        flex: 0 0 33.33333%; }
        .no-flexbox .grid__col--md-4 {
          width: 33.33333%;
          max-width: none; }
          .no-flexbox .grid__col--md-4:nth-child(3n + 4) {
            clear: both; }
      .grid__col--md-5 {
        max-width: 41.66667%;
        flex: 0 0 41.66667%; }
        .no-flexbox .grid__col--md-5 {
          width: 41.66667%;
          max-width: none; }
          .no-flexbox .grid__col--md-5:nth-child(2n + 3) {
            clear: both; }
      .grid__col--md-6 {
        max-width: 50%;
        flex: 0 0 50%; }
        .no-flexbox .grid__col--md-6 {
          width: 50%;
          max-width: none; }
          .no-flexbox .grid__col--md-6:nth-child(2n + 3) {
            clear: both; }
      .grid__col--md-7 {
        max-width: 58.33333%;
        flex: 0 0 58.33333%; }
        .no-flexbox .grid__col--md-7 {
          width: 58.33333%;
          max-width: none; }
          .no-flexbox .grid__col--md-7:nth-child(1n + 2) {
            clear: both; }
      .grid__col--md-8 {
        max-width: 66.66667%;
        flex: 0 0 66.66667%; }
        .no-flexbox .grid__col--md-8 {
          width: 66.66667%;
          max-width: none; }
          .no-flexbox .grid__col--md-8:nth-child(1n + 2) {
            clear: both; }
      .grid__col--md-9 {
        max-width: 75%;
        flex: 0 0 75%; }
        .no-flexbox .grid__col--md-9 {
          width: 75%;
          max-width: none; }
          .no-flexbox .grid__col--md-9:nth-child(1n + 2) {
            clear: both; }
      .grid__col--md-10 {
        max-width: 83.33333%;
        flex: 0 0 83.33333%; }
        .no-flexbox .grid__col--md-10 {
          width: 83.33333%;
          max-width: none; }
          .no-flexbox .grid__col--md-10:nth-child(1n + 2) {
            clear: both; }
      .grid__col--md-11 {
        max-width: 91.66667%;
        flex: 0 0 91.66667%; }
        .no-flexbox .grid__col--md-11 {
          width: 91.66667%;
          max-width: none; }
          .no-flexbox .grid__col--md-11:nth-child(1n + 2) {
            clear: both; }
      .grid__col--md-12 {
        max-width: 100%;
        flex: 0 0 100%; }
        .no-flexbox .grid__col--md-12 {
          width: 100%;
          max-width: none; }
          .no-flexbox .grid__col--md-12:nth-child(1n + 2) {
            clear: both; } }
    @media (max-width: 640px) {
      .grid__col--sm-1 {
        max-width: 8.33333%;
        flex: 0 0 8.33333%; }
        .no-flexbox .grid__col--sm-1 {
          width: 8.33333%;
          max-width: none; }
          .no-flexbox .grid__col--sm-1:nth-child(12n + 13) {
            clear: both; }
      .grid__col--sm-2 {
        max-width: 16.66667%;
        flex: 0 0 16.66667%; }
        .no-flexbox .grid__col--sm-2 {
          width: 16.66667%;
          max-width: none; }
          .no-flexbox .grid__col--sm-2:nth-child(6n + 7) {
            clear: both; }
      .grid__col--sm-3 {
        max-width: 25%;
        flex: 0 0 25%; }
        .no-flexbox .grid__col--sm-3 {
          width: 25%;
          max-width: none; }
          .no-flexbox .grid__col--sm-3:nth-child(4n + 5) {
            clear: both; }
      .grid__col--sm-4 {
        max-width: 33.33333%;
        flex: 0 0 33.33333%; }
        .no-flexbox .grid__col--sm-4 {
          width: 33.33333%;
          max-width: none; }
          .no-flexbox .grid__col--sm-4:nth-child(3n + 4) {
            clear: both; }
      .grid__col--sm-5 {
        max-width: 41.66667%;
        flex: 0 0 41.66667%; }
        .no-flexbox .grid__col--sm-5 {
          width: 41.66667%;
          max-width: none; }
          .no-flexbox .grid__col--sm-5:nth-child(2n + 3) {
            clear: both; }
      .grid__col--sm-6 {
        max-width: 50%;
        flex: 0 0 50%; }
        .no-flexbox .grid__col--sm-6 {
          width: 50%;
          max-width: none; }
          .no-flexbox .grid__col--sm-6:nth-child(2n + 3) {
            clear: both; }
      .grid__col--sm-7 {
        max-width: 58.33333%;
        flex: 0 0 58.33333%; }
        .no-flexbox .grid__col--sm-7 {
          width: 58.33333%;
          max-width: none; }
          .no-flexbox .grid__col--sm-7:nth-child(1n + 2) {
            clear: both; }
      .grid__col--sm-8 {
        max-width: 66.66667%;
        flex: 0 0 66.66667%; }
        .no-flexbox .grid__col--sm-8 {
          width: 66.66667%;
          max-width: none; }
          .no-flexbox .grid__col--sm-8:nth-child(1n + 2) {
            clear: both; }
      .grid__col--sm-9 {
        max-width: 75%;
        flex: 0 0 75%; }
        .no-flexbox .grid__col--sm-9 {
          width: 75%;
          max-width: none; }
          .no-flexbox .grid__col--sm-9:nth-child(1n + 2) {
            clear: both; }
      .grid__col--sm-10 {
        max-width: 83.33333%;
        flex: 0 0 83.33333%; }
        .no-flexbox .grid__col--sm-10 {
          width: 83.33333%;
          max-width: none; }
          .no-flexbox .grid__col--sm-10:nth-child(1n + 2) {
            clear: both; }
      .grid__col--sm-11 {
        max-width: 91.66667%;
        flex: 0 0 91.66667%; }
        .no-flexbox .grid__col--sm-11 {
          width: 91.66667%;
          max-width: none; }
          .no-flexbox .grid__col--sm-11:nth-child(1n + 2) {
            clear: both; }
      .grid__col--sm-12 {
        max-width: 100%;
        flex: 0 0 100%; }
        .no-flexbox .grid__col--sm-12 {
          width: 100%;
          max-width: none; }
          .no-flexbox .grid__col--sm-12:nth-child(1n + 2) {
            clear: both; } }
  .grid--flip {
    flex-direction: row-reverse; }
    .no-flexbox .grid--flip > .grid__col {
      float: right; }
  .grid[class*="reverse"] > .grid__col:not([class*="--sm-"]), .grid[class*="reverse"] > .grid__col:not([class*="--sm-"]):first-child {
    margin: 2rem 0 0; }
  .grid[class*="reverse"] > .grid__col:not([class*="--sm-"]):last-child {
    margin-top: 0; }
  @media (max-width: 640px) {
    .grid--sm-reverse {
      flex-wrap: wrap-reverse; } }
  @media (max-width: 959px) {
    .grid--md-reverse {
      flex-wrap: wrap-reverse; } }
  @media (max-width: 999px) {
    .grid--lg-reverse {
      flex-wrap: wrap-reverse; } }
  .grid--debug .grid__col > * {
    background: rgba(18, 38, 74, 0.15); }
  @media (max-width: 640px) {
    .grid--responsive > .grid__col:not([class*="--sm-"]) {
      display: block;
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
      margin: 2rem 0 0; }
      .grid--responsive > .grid__col:not([class*="--sm-"]):first-child {
        margin-top: 0; } }
  @media (max-width: 640px) {
    .grid--sm-responsive > .grid__col:not([class*="--sm-"]) {
      display: block;
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
      margin: 2rem 0 0; }
      .grid--sm-responsive > .grid__col:not([class*="--sm-"]):first-child {
        margin-top: 0; } }
  @media (max-width: 959px) {
    .grid--md-responsive > .grid__col:not([class*="--sm-"]) {
      display: block;
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
      margin: 2rem 0 0; }
      .grid--md-responsive > .grid__col:not([class*="--sm-"]):first-child {
        margin-top: 0; } }
  @media (max-width: 999px) {
    .grid--lg-responsive > .grid__col:not([class*="--sm-"]) {
      display: block;
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
      margin: 2rem 0 0; }
      .grid--lg-responsive > .grid__col:not([class*="--sm-"]):first-child {
        margin-top: 0; } }

/*==========================================================================
  basic components
  ==========================================================================*/
.btn {
  display: inline-block;
  padding: 0.297rem 0.66rem 0.33rem;
  color: #FFF;
  background: #27509b;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  outline: 0; }
  .btn:hover, .btn:focus {
    color: #FFF;
    background: #12264a;
    text-decoration: none; }

/* Video Trigger */
.video_trigger {
  padding: 2rem;
  background: #e7e9eb;
  text-align: center; }
  .video_trigger:before {
    content: '';
    display: inline-block;
    position: relative;
    width: 100%;
    height: 5em;
    vertical-align: middle;
    margin-right: 0.6em;
    background: url("../assets/youtube-icon-red.svg") no-repeat center;
    background-size: 15rem; }
  .video_trigger p {
    width: 80%;
    margin: 2rem auto; }

.ce-gallery figcaption.video-caption {
  display: none; }

iframe[data-scaling="true"] {
  min-width: 100%;
  max-width: 100%; }

@font-face {
  font-family: "Source Sans Pro";
  src: url("../assets/fonts/SourceSansPro-Light.woff2");
  src: local("☺"), url("../assets/fonts/SourceSansPro-Light.woff") format("woff"), url("../assets/fonts/SourceSansPro-Light.eot") format("embedded-opentype"), url("../assets/fonts/SourceSansPro-Light.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/SourceSansPro-Light.ttf") format("truetype"), url("../assets/fonts/SourceSansPro-Light.svg") format("svg");
  font-weight: 300;
  font-style: normal;
  font-variant: normal; }

@font-face {
  font-family: "Source Sans Pro";
  src: url("../assets/fonts/SourceSansPro-LightItalic.woff2");
  src: local("☺"), url("../assets/fonts/SourceSansPro-LightItalic.woff") format("woff"), url("../assets/fonts/SourceSansPro-LightItalic.eot") format("embedded-opentype"), url("../assets/fonts/SourceSansPro-LightItalic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/SourceSansPro-LightItalic.ttf") format("truetype"), url("../assets/fonts/SourceSansPro-LightItalic.svg") format("svg");
  font-weight: 300;
  font-style: italic;
  font-variant: normal; }

@font-face {
  font-family: "Source Sans Pro";
  src: url("../assets/fonts/SourceSansPro-Regular.woff2");
  src: local("☺"), url("../assets/fonts/SourceSansPro-Regular.woff") format("woff"), url("../assets/fonts/SourceSansPro-Regular.eot") format("embedded-opentype"), url("../assets/fonts/SourceSansPro-Regular.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/SourceSansPro-Regular.ttf") format("truetype"), url("../assets/fonts/SourceSansPro-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-variant: normal; }

@font-face {
  font-family: "Source Sans Pro";
  src: url("../assets/fonts/SourceSansPro-Italic.woff2");
  src: local("☺"), url("../assets/fonts/SourceSansPro-Italic.woff") format("woff"), url("../assets/fonts/SourceSansPro-Italic.eot") format("embedded-opentype"), url("../assets/fonts/SourceSansPro-Italic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/SourceSansPro-Italic.ttf") format("truetype"), url("../assets/fonts/SourceSansPro-Italic.svg") format("svg");
  font-weight: normal;
  font-style: italic;
  font-variant: normal; }

@font-face {
  font-family: "Source Sans Pro";
  src: url("../assets/fonts/SourceSansPro-SemiBold.woff2");
  src: local("☺"), url("../assets/fonts/SourceSansPro-SemiBold.woff") format("woff"), url("../assets/fonts/SourceSansPro-SemiBold.eot") format("embedded-opentype"), url("../assets/fonts/SourceSansPro-SemiBold.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/SourceSansPro-SemiBold.ttf") format("truetype"), url("../assets/fonts/SourceSansPro-SemiBold.svg") format("svg");
  font-weight: 600;
  font-style: normal;
  font-variant: normal; }

@font-face {
  font-family: "Source Sans Pro";
  src: url("../assets/fonts/SourceSansPro-SemiBoldItalic.woff2");
  src: local("☺"), url("../assets/fonts/SourceSansPro-SemiBoldItalic.woff") format("woff"), url("../assets/fonts/SourceSansPro-SemiBoldItalic.eot") format("embedded-opentype"), url("../assets/fonts/SourceSansPro-SemiBoldItalic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/SourceSansPro-SemiBoldItalic.ttf") format("truetype"), url("../assets/fonts/SourceSansPro-SemiBoldItalic.svg") format("svg");
  font-weight: 600;
  font-style: italic;
  font-variant: normal; }

@font-face {
  font-family: "Source Sans Pro";
  src: url("../assets/fonts/SourceSansPro-Bold.woff2");
  src: local("☺"), url("../assets/fonts/SourceSansPro-Bold.woff") format("woff"), url("../assets/fonts/SourceSansPro-Bold.eot") format("embedded-opentype"), url("../assets/fonts/SourceSansPro-Bold.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/SourceSansPro-Bold.ttf") format("truetype"), url("../assets/fonts/SourceSansPro-Bold.svg") format("svg");
  font-weight: bold;
  font-style: normal;
  font-variant: normal; }

@font-face {
  font-family: "Source Sans Pro";
  src: url("../assets/fonts/SourceSansPro-BoldItalic.woff2");
  src: local("☺"), url("../assets/fonts/SourceSansPro-BoldItalic.woff") format("woff"), url("../assets/fonts/SourceSansPro-BoldItalic.eot") format("embedded-opentype"), url("../assets/fonts/SourceSansPro-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/SourceSansPro-BoldItalic.ttf") format("truetype"), url("../assets/fonts/SourceSansPro-BoldItalic.svg") format("svg");
  font-weight: bold;
  font-style: italic;
  font-variant: normal; }

*:focus {
  outline-style: none;
  box-shadow: 0px 1px #b5b5b5; }

body {
  overflow-x: hidden; }

h1 {
  font-size: 4em; }

h2 {
  font-size: 3em; }

h3 {
  font-size: 2em; }

h4 {
  font-size: 1.5em; }

h5,
h6 {
  font-size: 1.0em; }

@media (max-width: 1200px) {
  h1 {
    font-size: 3em; }
  h2 {
    font-size: 2em; }
  h3 {
    font-size: 1.5em; }
  h4 {
    font-size: 1.2em; }
  h5,
  h6 {
    font-size: 1.0em; } }

@media (max-width: 640px) {
  h1 {
    font-size: 2.5em; }
  h2 {
    font-size: 1.75em; } }

a {
  transition: color .15s ease, background-color .15s ease; }
  a:hover {
    text-decoration: none; }

ul:not([class]) {
  list-style-type: square;
  padding: 0;
  margin-left: 1.25em; }
  ul:not([class]) li ul {
    list-style-type: none;
    color: #b5b5b5;
    margin-left: 0; }
    ul:not([class]) li ul li:before {
      content: "–";
      text-indent: -5px;
      margin-left: -1.25em;
      margin-right: 0.75em; }

table {
  position: relative;
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  margin-top: 2rem;
  margin-bottom: 2rem; }
  table th, table td {
    padding: 1rem 2rem;
    font-weight: normal;
    text-align: left; }
  table thead *,
  table thead a {
    color: #FFF; }
  table thead th {
    text-align: left;
    background: #1f4384; }
    table thead th[colspan] {
      padding-left: 2.5rem; }
      table thead th[colspan]:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        height: 100%;
        width: 0.5rem;
        background: #FFF;
        transform: translateX(-2.5rem);
        z-index: 1; }
  table tfoot {
    display: table-row-group; }
    table tfoot th {
      background: #4c74bc;
      color: #FFF; }
      table tfoot th[data-style="light"] {
        background: #7191ca; }
  table tbody {
    background: #e7e9eb; }
    table tbody tr {
      transition: background-color .15s ease;
      background: transparent; }
      table tbody tr:nth-child(even) {
        background: rgba(0, 0, 0, 0.1); }
      table tbody tr:hover {
        background: rgba(0, 0, 0, 0.2); }
      table tbody tr td[data-style="light"] {
        background: rgba(255, 255, 255, 0.3); }
      table tbody tr td[data-mobile] {
        display: none; }
  @media (max-width: 640px) {
    table thead, table tfoot {
      display: none; }
    table tbody {
      background: none; }
      table tbody > tr {
        background: #e7e9eb;
        display: block; }
        table tbody > tr + tr {
          margin-top: 3rem; }
      table tbody tr td {
        display: none; }
        table tbody tr td[data-mobile] {
          display: block;
          padding: 0; }
          table tbody tr td[data-mobile] table {
            margin: -2rem 0; }
            table tbody tr td[data-mobile] table tr:nth-child(even) {
              background: rgba(0, 0, 0, 0.1); }
            table tbody tr td[data-mobile] table tbody tr {
              display: table-row; }
              table tbody tr td[data-mobile] table tbody tr td {
                display: table-cell; }
                table tbody tr td[data-mobile] table tbody tr td[data-style="blue"] {
                  background: #1f4384;
                  color: #FFF; } }

.fancybox {
  display: inline-block; }
  .fancybox[title] {
    display: block;
    position: relative; }
    .fancybox[title]:before {
      position: absolute;
      content: attr(title);
      text-transform: uppercase;
      bottom: 0;
      right: 0;
      pointer-events: none;
      font-weight: 600;
      color: #888;
      font-size: 1.15em;
      background: url("../assets/zoom-icon.svg") no-repeat;
      background-color: #FFF;
      background-size: 1.3em;
      background-position: right 0.4em center;
      padding: .5em 2em .5em 1em; }
  .fancybox-content .fancybox-image {
    background: #FFF; }

[data-in-view="false"] {
  opacity: 0; }

[data-in-view="true"] {
  animation: fade-in-up ease 0.5s both; }
  [data-in-view="true"] > * {
    animation: fade-in-up ease 1s both; }

.slick-arrow {
  --arrow-size: 2em;
  display: block;
  position: absolute;
  top: 50%;
  width: var(--arrow-size);
  height: var(--arrow-size);
  transform: translateY(-50%);
  overflow: hidden;
  text-indent: -99em;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent no-repeat center;
  z-index: 1; }

.slick-prev {
  left: 2rem;
  background-image: url("../assets/slide-left.svg"); }

.slick-next {
  right: 2rem;
  background-image: url("../assets/slide-right.svg"); }

.slick-dots {
  display: flex;
  position: absolute;
  left: 50%;
  bottom: 2rem;
  transform: translate(-50%);
  list-style: none;
  margin: 0;
  padding: 0;
  list-style-image: none; }
  .slick-dots button {
    margin: 0 0.5rem;
    padding: 0;
    width: 1em;
    height: 1em;
    overflow: hidden;
    text-indent: -99em;
    cursor: pointer;
    color: transparent;
    border-radius: 50%;
    border: 2px solid #FFF;
    transition: background-color .5s ease;
    background: #b5b5b5; }
  .slick-dots .slick-active button {
    background: #c20e1a; }

@media (min-width: 960px) {
  .slick-arrow {
    --arrow-size: 3.5em; } }

@media (max-width: 640px) {
  .slick-arrow {
    display: none; } }

.content-full-width {
  position: relative;
  left: 50%;
  right: 50%;
  width: 100vw;
  margin-left: -50vw;
  margin-right: -50vw; }
  @media (max-width: 1200px) {
    .content-full-width {
      padding: 0 4.5rem; } }
  @media (max-width: 959px) {
    .content-full-width {
      padding: 0 2.5rem; } }
  @media (max-width: 375px) {
    .content-full-width {
      padding: 0; } }

.light {
  font-weight: 300; }

.semibold {
  font-weight: 600; }

.frame-layout-2, .frame-layout-3 {
  box-sizing: border-box; }
  .frame-layout-2 > section, .frame-layout-3 > section {
    padding: 2rem; }

.frame-layout-2 {
  background: #FFF; }
  .frame-layout-2 > section {
    background: #e7e9eb; }

.frame-layout-3 {
  background: #e7e9eb; }
  .frame-layout-3 > section {
    background: #FFF; }

.frame-layout-2.content-full-width > section, .frame-layout-3.content-full-width > section {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  box-sizing: border-box; }

@media (max-width: 375px) {
  .frame-layout-2.content-full-width, .frame-layout-3.content-full-width {
    padding-left: 1rem;
    padding-right: 1rem; } }

.frame-layout-3.centered {
  padding-left: 4rem;
  padding-right: 4rem; }
  @media (max-width: 375px) {
    .frame-layout-3.centered {
      padding-left: 2rem;
      padding-right: 2rem; } }

.frame-layout-4 {
  background-color: #e7e9eb; }

@media (max-width: 959px) {
  .ce-textpic .ce-gallery, .ce-textpic.ce-intext .ce-gallery {
    float: none;
    margin: 0.5rem 0; } }

.ce-textpic > a {
  width: 100%; }
  .ce-textpic > a .ce-bodytext {
    width: 100%; }

.ce-textpic .ce-column {
  width: 100%; }

.ce-textpic.ce-center .ce-gallery figure {
  margin: 0 auto; }

.ce-textpic.ce-nowrap {
  margin: -2rem;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row; }
  @media (max-width: 768px) {
    .ce-textpic.ce-nowrap {
      flex-wrap: wrap; } }
  .ce-textpic.ce-nowrap.ce-right {
    flex-direction: row-reverse; }
  .ce-textpic.ce-nowrap .ce-bodytext {
    flex: 1 0 auto;
    width: 50%;
    box-sizing: border-box; }
    .ce-textpic.ce-nowrap .ce-bodytext h2 {
      color: black; }
      .ce-textpic.ce-nowrap .ce-bodytext h2 a {
        color: black; }
        .ce-textpic.ce-nowrap .ce-bodytext h2 a:visited, .ce-textpic.ce-nowrap .ce-bodytext h2 a:hover, .ce-textpic.ce-nowrap .ce-bodytext h2 a:link {
          color: black;
          text-decoration: none; }
  .ce-textpic.ce-nowrap > a .ce-bodytext {
    width: 100%; }
  .ce-textpic.ce-nowrap .ce-gallery {
    width: 50%;
    margin: 0;
    flex: 1 0 auto; }
    .ce-textpic.ce-nowrap .ce-gallery .ce-row,
    .ce-textpic.ce-nowrap .ce-gallery .ce-column,
    .ce-textpic.ce-nowrap .ce-gallery figure,
    .ce-textpic.ce-nowrap .ce-gallery .fancybox,
    .ce-textpic.ce-nowrap .ce-gallery img {
      height: 100%; }
    @media (max-width: 640px) {
      .ce-textpic.ce-nowrap .ce-gallery {
        max-width: none;
        width: 100%;
        max-height: 40vw; }
        .ce-textpic.ce-nowrap .ce-gallery .ce-row,
        .ce-textpic.ce-nowrap .ce-gallery .ce-column,
        .ce-textpic.ce-nowrap .ce-gallery figure,
        .ce-textpic.ce-nowrap .ce-gallery .fancybox,
        .ce-textpic.ce-nowrap .ce-gallery img {
          max-height: inherit;
          width: 100%;
          height: 100%; } }
    .ce-textpic.ce-nowrap .ce-gallery img {
      display: block;
      object-fit: cover;
      width: 100%;
      height: 100%;
      max-width: none; }
    @media (max-width: 500px) {
      .ce-textpic.ce-nowrap .ce-gallery {
        max-height: 60vw; } }
  .ce-textpic.ce-nowrap .ce-bodytext {
    padding: 2rem; }

.frame[class*="frame-space"], .frame[class*="frame-space"] {
  margin-top: 0;
  margin-bottom: 0; }

.frame-space-before-extra-small {
  padding-top: 0.3em; }

.frame-space-before-small {
  padding-top: 2em; }

.frame-space-before-medium {
  padding-top: 3em; }

.frame-space-before-large {
  padding-top: 4em; }

.frame-space-before-extra-large {
  padding-top: 5em; }

.frame-space-after-extra-small {
  padding-bottom: 0.3em; }

.frame-space-after-small {
  padding-bottom: 2em; }

.frame-space-after-medium {
  padding-bottom: 3em; }

.frame-space-after-large {
  padding-bottom: 4em; }

.frame-space-after-extra-large {
  padding-bottom: 5em; }

.tippy-popper {
  margin-top: -20px;
  margin-bottom: -20px;
  margin-left: -20px;
  margin-right: -20px; }
  .tippy-popper[x-placement^=top-start] {
    left: 100px !important; }
  .tippy-popper[x-placement^=top] .tippy-tooltip.faudi-theme .tippy-arrow {
    border: 10px solid transparent;
    border-top: 10px solid #1f4384;
    top: 100%;
    left: 10px !important; }
  .tippy-popper[x-placement^=bottom-start] {
    left: 100px !important; }
  .tippy-popper[x-placement^=bottom] .tippy-tooltip.faudi-theme .tippy-arrow {
    border: 10px solid transparent;
    border-bottom: 10px solid #1f4384;
    bottom: 100%;
    left: 10px !important; }
  .tippy-popper[x-placement^=left] .tippy-tooltip.faudi-theme .tippy-arrow {
    border: 10px solid transparent;
    border-left: 10px solid #1f4384;
    left: 100%; }
  .tippy-popper[x-placement^=right] .tippy-tooltip.faudi-theme .tippy-arrow {
    border: 10px solid transparent;
    border-right: 10px solid #1f4384;
    right: 100%; }
  .tippy-popper .tippy-tooltip.faudi-theme {
    background: #1f4384;
    color: #FFF; }
    .tippy-popper .tippy-tooltip.faudi-theme[data-animatefill] {
      background-color: transparent; }
    .tippy-popper .tippy-tooltip.faudi-theme .tippy-backdrop {
      background: #1f4384; }
    .tippy-popper .tippy-tooltip.faudi-theme .tippy-roundarrow {
      fill: #1f4384; }

.collapsible__list {
  list-style: none;
  margin: 0;
  padding: 0;
  list-style-image: none; }

.collapsible + .collapsible {
  margin-top: 0.5rem; }

.collapsible__item {
  position: relative; }
  .collapsible__item + .collapsible__item {
    margin-top: 0.5rem; }
  .collapsible__item:before, .collapsible__item:after {
    display: block;
    position: absolute;
    content: "";
    pointer-events: none;
    right: 0.75rem;
    top: 1.4rem;
    width: 1.5rem;
    height: 4px;
    background: #FFF;
    z-index: 1;
    transform: rotate(0deg);
    transition: transform .2s; }
  .collapsible__item:after {
    transform: rotate(90deg); }
  .collapsible__item--active:before {
    transform: rotate(180deg); }
  .collapsible__item--active:after {
    transform: rotate(180deg); }
  .collapsible__item--active .collapsible__head {
    background: #1f4384; }
    .collapsible__item--active .collapsible__head:hover {
      background: #1a396f; }

.collapsible__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background: #27509b;
  transition: 0.2s; }
  .collapsible__head *,
  .collapsible__head a {
    color: #FFF; }
  .collapsible__head h2 {
    line-height: 3em; }
  .collapsible__head:hover {
    background: #1f4384; }
  .collapsible__head:after {
    display: block;
    position: absolute;
    content: "";
    pointer-events: none;
    right: 0;
    top: 0;
    width: calc(3rem - 1px);
    height: calc(3rem - 1px);
    background: #000;
    z-index: 0; }

.collapsible__title {
  padding: 0 2rem;
  font-size: .85em; }

.collapsible__preview {
  width: 15em;
  overflow: hidden; }
  .collapsible__preview img {
    display: block;
    animation: slide-zoom 10s ease-in-out infinite alternate; }

@media (min-width: 960px) {
  .collapsible__item:after {
    font-size: 2em; } }

@media (max-width: 640px) {
  .collapsible__title {
    padding: 1rem;
    font-size: .4em; }
  .collapsible__preview {
    display: none; } }

@keyframes slide-zoom {
  to {
    transform: scale(1.2); } }

.collapsible .collapsible .collapsible__head {
  overflow: hidden;
  background-color: #2E74C0;
  height: calc(3rem - 1px); }
  .collapsible .collapsible .collapsible__head h2 {
    font-weight: lighter;
    font-size: 1.5rem; }
  .collapsible .collapsible .collapsible__head:after {
    height: calc(3rem - 1px);
    background-color: #004f9e; }

.collapsible-country__list {
  list-style: none;
  margin: 0;
  padding: 0;
  list-style-image: none; }

.collapsible-country + .collapsible-country {
  margin-top: 0.5rem; }

.collapsible-country__item {
  position: relative; }
  .collapsible-country__item + .collapsible-country__item {
    margin-top: 0.5rem; }
  .collapsible-country__item--active .collapsible-country__head:before {
    transform: rotate(0.5turn); }

.collapsible-country__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  cursor: pointer;
  background: #e7e9eb; }
  .collapsible-country__head:before {
    content: '';
    background-image: url("../assets/dropdown.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 1.5em;
    height: 1.5em;
    display: block;
    transform: rotate(0.25turn);
    opacity: 0.4;
    transition: 0.2s;
    background-image: url("../assets/dropdown-dark.svg");
    position: absolute;
    right: 1em; }

.collapsible-country__title {
  padding: 1rem 2rem;
  font-weight: 500; }
  .collapsible-country__title h2 {
    font-size: 1em;
    color: #656565; }

.collapsible-country__preview {
  width: 15em;
  overflow: hidden; }
  .collapsible-country__preview img {
    display: block;
    animation: slide-zoom 10s ease-in-out infinite alternate; }

@media (min-width: 960px) {
  .collapsible-country__item:after {
    font-size: 2em; } }

@media (max-width: 640px) {
  .collapsible-country__title {
    padding: 1rem;
    font-size: 0.8rem; }
  .collapsible-country__preview {
    display: none; } }

@keyframes slide-zoom {
  to {
    transform: scale(1.2); } }

.powermail_form {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  position: relative;
  transition: 0.2s; }
  .powermail_form *:focus {
    outline: none; }
  .powermail_form ::placeholder {
    color: #FFF;
    opacity: .5; }
  .powermail_form fieldset {
    padding: 0;
    margin: 0;
    border: none;
    z-index: 2; }
  .powermail_form .powermail_fieldwrap {
    margin-bottom: 2rem; }
  .powermail_form input[type="text"],
  .powermail_form input[type="email"],
  .powermail_form input[type="url"],
  .powermail_form input[type="tel"] {
    padding: 1rem 0 1rem 1.5rem;
    border: 0;
    border-radius: 0;
    background-color: #436cb5;
    color: #FFF;
    width: 100%;
    box-sizing: border-box;
    z-index: 2; }
    .powermail_form input[type="text"]:focus,
    .powermail_form input[type="email"]:focus,
    .powermail_form input[type="url"]:focus,
    .powermail_form input[type="tel"]:focus {
      outline: none; }
  .powermail_form input[type="checkbox"],
  .powermail_form input[type="radio"] {
    appearance: none;
    outline: none;
    cursor: pointer;
    border-radius: 0;
    margin-right: 0.5em; }
    .powermail_form input[type="checkbox"]:before,
    .powermail_form input[type="radio"]:before {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      content: "×";
      color: transparent;
      width: 1em;
      height: 1em;
      background: #436cb5; }
    .powermail_form input[type="checkbox"]:checked:before,
    .powermail_form input[type="radio"]:checked:before {
      color: #FFF; }
    .powermail_form input[type="checkbox"]:focus,
    .powermail_form input[type="radio"]:focus {
      outline: none; }
  .powermail_form input[type="radio"]:before {
    content: "·";
    border-radius: 50%; }
  .powermail_form input[type="radio"]:focus {
    outline: none; }
  .powermail_form select {
    position: relative;
    padding: 1rem 2rem 1rem 1.5rem;
    width: 100%;
    box-sizing: border-box;
    appearance: none;
    border: 0;
    border-radius: 0;
    background: #436cb5 url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='white'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
    background-position: calc(100% - 1em) calc(50% + 0.2em);
    background-size: .75em;
    color: #fff; }
    .powermail_form select:focus {
      outline: none; }
    .powermail_form select option[disabled] {
      color: rgba(255, 255, 255, 0.5); }
  .powermail_form textarea {
    display: block;
    min-width: 100%;
    max-width: 100%;
    min-height: 5.5em;
    height: 5.5em;
    padding: 1rem 1.5rem;
    border: 0;
    box-sizing: border-box;
    background-color: #436cb5;
    color: #FFF;
    transition: height 0.2s;
    border-radius: 0; }
    .powermail_form textarea:focus {
      outline: none; }
  .powermail_form button,
  .powermail_form input[type="submit"],
  .powermail_form input[type="button"],
  .powermail_form input[type="reset"] {
    width: auto;
    padding: 0.5rem;
    box-sizing: border-box;
    float: none;
    border: 0;
    cursor: pointer;
    color: #FFF;
    background-color: #000;
    font-weight: 600;
    text-transform: uppercase;
    transition: 0.2s;
    border-radius: 0; }
    .powermail_form button:hover,
    .powermail_form input[type="submit"]:hover,
    .powermail_form input[type="button"]:hover,
    .powermail_form input[type="reset"]:hover {
      background: #1f4384; }
  .powermail_form label {
    cursor: pointer; }
  @media (min-width: 960px) {
    .footer__contact .powermail_form:focus-within {
      transition: 0.2s;
      margin-top: -7.5em; }
      .footer__contact .powermail_form:focus-within:before {
        transform: translateY(-100%); }
      .footer__contact .powermail_form:focus-within textarea {
        height: 13em; }
    .footer__contact .powermail_form:hover {
      transition-delay: 0.1s; }
      .footer__contact .powermail_form:hover:before {
        transition-delay: 0.1s; }
      .footer__contact .powermail_form:hover textarea {
        transition-delay: 0.1s; } }
  @media (max-width: 959px) {
    .powermail_form {
      padding: 0; }
      .footer__contact .powermail_form:before {
        padding: 2rem 0;
        position: relative;
        transform: none;
        z-index: 1; }
      .powermail_form textarea {
        min-height: 13em; } }

/* Bewerbungs Formular  */
.tx-powermail form.layout1 {
  background-color: #eee;
  margin-bottom: 20px; }

.powermail_fieldset.layout1, .powermail_fieldset.layout2 {
  margin: 25px 0; }
  .powermail_fieldset.layout1 .powermail_legend, .powermail_fieldset.layout2 .powermail_legend {
    display: none; }
  .powermail_fieldset.layout1 .powermail_fieldwrap, .powermail_fieldset.layout2 .powermail_fieldwrap {
    float: left;
    margin: 0 1em; }
    .powermail_fieldset.layout1 .powermail_fieldwrap label, .powermail_fieldset.layout2 .powermail_fieldwrap label {
      font-weight: bold;
      color: #27509b; }

.powermail_fieldset.layout1 .powermail_fieldwrap {
  width: 94%; }

.powermail_fieldset.layout2 .powermail_fieldwrap {
  width: 45%; }

.powermail_form .layout2 input[type="text"],
.powermail_form .layout2 input[type="email"],
.powermail_form .layout2 input[type="url"],
.powermail_form .layout2 input[type="tel"],
.powermail_form .layout2 input[type="file"],
.powermail_form .layout2 input[type="date"],
.powermail_form .layout2 textarea,
.powermail_form .layout2 select, .powermail_form .layout1 input[type="text"],
.powermail_form .layout1 input[type="email"],
.powermail_form .layout1 input[type="url"],
.powermail_form .layout1 input[type="tel"],
.powermail_form .layout1 input[type="file"],
.powermail_form .layout1 input[type="date"],
.powermail_form .layout1 textarea,
.powermail_form .layout1 select {
  background: #fff;
  border: 1px solid #436cb5;
  color: #436cb5;
  padding: 1rem 0 1rem 1.5rem;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px; }

.powermail_form .layout2 input[type="date"], .powermail_form .layout1 input[type="date"] {
  padding: 1rem 0 0.75rem 1.5rem; }

.powermail_form .layout2 input[type="file"], .powermail_form .layout1 input[type="file"] {
  padding: 0.75rem 0 0.9rem 0.5rem; }

.powermail_form input[type="reset"] {
  background-color: #436cb5; }

.powermail_fieldset.layout1 .powermail_fieldwrap.layout2 {
  width: 45%; }

.powermail_fieldset.layout1 .powermail_fieldwrap.layout2 label {
  display: none; }

.powermail_fieldset.layout2 .powermail_fieldwrap.layout1 {
  width: 100%; }

.powermail_field h4 {
  margin-bottom: 20px; }

.content {
  padding: 0 2rem;
  position: relative;
  margin: 0 auto;
  min-width: 240px;
  max-width: 1000px; }
  @media (max-width: 1200px) {
    .content {
      padding: 0 4rem; } }
  @media (max-width: 375px) {
    .content {
      padding: 0 1rem; } }

.cc-window {
  position: fixed;
  display: flex;
  flex-direction: row;
  background: white;
  padding: 1em 1.8em;
  z-index: 10; }

.cc-banner {
  left: 0;
  right: 0;
  bottom: 0; }

.cc-message {
  flex: 1; }

.cc-link {
  opacity: .8;
  display: inline-block;
  padding: .2em; }

.cc-btn {
  display: block;
  padding: .4em .8em;
  font-size: .9em;
  font-weight: 700;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  min-width: 140px;
  margin-left: .5em;
  flex: 1;
  color: white;
  background-color: #3e6375;
  border-color: transparent; }

.companies__traits {
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  background: #f5f6f7; }
  .companies__traits p {
    margin: 0;
    padding-right: 2rem;
    color: #6c6c6c; }
    .companies__traits p span {
      vertical-align: middle; }

.companies__list {
  list-style: none;
  margin: 0;
  padding: 0;
  list-style-image: none; }

.companies__item {
  padding: 2rem;
  background-color: #eff1f4;
  margin-bottom: 0.5rem; }
  .companies__item:last-of-type {
    margin-bottom: 0; }
  .companies__item .grid__col {
    margin: 0 !important; }

.companies__info p {
  margin: 0; }

.companies__aside {
  display: flex;
  justify-content: flex-end;
  position: relative; }

.companies__logo {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  margin-top: -2rem;
  max-width: 150px;
  max-height: 150px;
  background: #FFF;
  margin-right: 2.5rem; }
  .companies__logo img {
    display: block;
    width: 100%;
    height: 100%;
    background: #FFF; }

.companies__labels {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: -1rem;
  top: 0; }
  .companies__labels .filter-icon {
    margin-bottom: 1rem; }

@media (max-width: 640px) {
  .companies .__traits {
    padding: 1rem; }
  .companies__aside {
    justify-content: space-between;
    margin-bottom: 1rem; } }

.distributor-become-btn {
  display: inline-block;
  padding: .5em .9em .5em 0;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
  line-height: 2em;
  display: block;
  background-size: 2em !important;
  background-position: left 0.5em center !important;
  background-color: #e7e9eb !important;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  padding-left: 0.5rem; }
  .distributor-become-btn:before {
    content: '';
    display: inline-block;
    position: relative;
    width: 2em;
    height: 2em;
    vertical-align: middle;
    margin-right: 0.6em;
    transform: translateY(-0.05em);
    transform-origin: center bottom; }
  .distributor-become-btn:hover:before {
    animation-name: bounce;
    animation-duration: 0.5s; }
  .distributor-become-btn, .distributor-become-btn--light {
    color: #000; }
    .distributor-become-btn:before, .distributor-become-btn--light:before {
      background: url("../assets/distributor_become-icon.svg") left center no-repeat; }
  .distributor-become-btn--dark {
    color: #FFF; }
    .distributor-become-btn--dark:before {
      background: url("../assets/distributor_become-icon.svg") left center no-repeat; }
  .distributor-become-btn:before {
    width: 2rem;
    height: 2rem; }

.distributor-find-btn {
  display: inline-block;
  padding: .5em .9em .5em 0;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
  line-height: 2em;
  display: block;
  background-size: 2em !important;
  background-position: left 0.5em center !important;
  background-color: #e7e9eb !important;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  padding-left: 0.5rem; }
  .distributor-find-btn:before {
    content: '';
    display: inline-block;
    position: relative;
    width: 2em;
    height: 2em;
    vertical-align: middle;
    margin-right: 0.6em;
    transform: translateY(-0.05em);
    transform-origin: center bottom; }
  .distributor-find-btn:hover:before {
    animation-name: bounce;
    animation-duration: 0.5s; }
  .distributor-find-btn, .distributor-find-btn--light {
    color: #000; }
    .distributor-find-btn:before, .distributor-find-btn--light:before {
      background: url("../assets/distributor_find-icon.svg") left center no-repeat; }
  .distributor-find-btn--dark {
    color: #FFF; }
    .distributor-find-btn--dark:before {
      background: url("../assets/distributor_find-icon.svg") left center no-repeat; }
  .distributor-find-btn:before {
    width: 2rem;
    height: 2rem; }

.download-btn {
  display: inline-block;
  padding: .5em .9em .5em 0;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
  line-height: 2em;
  background-color: #b5b5b5 !important;
  margin-top: 0.5em;
  padding-bottom: 0;
  padding-top: 0; }
  .download-btn:before {
    content: '';
    display: inline-block;
    position: relative;
    width: 2em;
    height: 2em;
    vertical-align: middle;
    margin-right: 0.6em;
    transform: translateY(-0.05em);
    transform-origin: center bottom; }
  .download-btn:hover:before {
    animation-name: bounce;
    animation-duration: 0.5s; }
  .download-btn, .download-btn--light {
    color: #000; }
    .download-btn:before, .download-btn--light:before {
      background: url("../assets/download-icon.svg") left center no-repeat; }
  .download-btn--dark {
    color: #FFF; }
    .download-btn--dark:before {
      background: url("../assets/download-icon.svg") left center no-repeat; }
  .download-btn:hover:before {
    animation: none; }

.footer {
  display: flex;
  flex-direction: column;
  background: #1f4384; }
  .footer *,
  .footer a {
    color: #FFF; }
  .footer__info {
    padding: 4rem 0 2rem;
    background: #162c55; }
  .footer__claim {
    font-size: 4em;
    line-height: 1.2; }
  .footer__image {
    text-align: center; }
  .footer__contact {
    padding: 6rem 0;
    background: #27509b; }
    .footer__contact__form {
      margin-top: 2rem; }
      .footer__contact__form .powermail_textarea {
        resize: none; }
    .footer__contact form {
      background: #27509b; }
    .footer__contact__address, .footer__contact__options {
      position: relative;
      font-style: normal;
      line-height: 2; }
      .footer__contact__address:before, .footer__contact__options:before {
        position: absolute;
        content: "";
        margin-top: -2em;
        width: 1.5em;
        height: 1.5em;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: .2; }
    .footer__contact__address {
      top: -1rem; }
      .footer__contact__address:before {
        background-image: url("../assets/location-icon-white.svg"); }
    .footer__contact__options {
      margin-top: 5rem; }
      .footer__contact__options:before {
        background-image: url("../assets/contact-icon-white.svg"); }
  .footer__nav {
    padding: 0 0 4rem;
    background: #27509b; }
    .footer__nav__list {
      list-style: none;
      margin: 0;
      padding: 0;
      list-style-image: none;
      padding-top: 4rem;
      border-top: 0.1em #4c74bc solid; }
    .footer__nav__item {
      margin-bottom: 1rem; }
      .footer__nav__item a {
        display: block;
        opacity: .4;
        text-decoration: none; }
        .footer__nav__item a:hover {
          opacity: 1; }
        .footer__nav__item a:before {
          content: "· "; }
  .footer__bottom {
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
    box-sizing: border-box;
    width: 100%;
    min-width: 240px;
    max-width: 1000px;
    position: relative;
    margin: 0 auto; }
  .footer__copy {
    padding: 1rem 0;
    font-size: .85em; }
  @media (min-width: 960px) {
    .footer__nav__list {
      column-count: 3;
      column-gap: 1rem; } }
  @media (max-width: 640px) {
    .footer__claim {
      font-size: 3em; }
    .footer__image--mobile {
      display: flex; }
    .footer__nav__list {
      display: flex;
      flex-wrap: wrap; }
    .footer__nav__item {
      width: 50%; } }

.filter-icon {
  display: inline-block;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: middle;
  background: url("../assets/filter-icon.svg") left center no-repeat; }

.gallery {
  position: relative; }
  .gallery__list {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 15em 15em;
    grid-template-areas: "small-1 large" "small-2 large";
    list-style: none;
    margin: 0;
    padding: 0;
    list-style-image: none; }
  .gallery__item {
    position: relative;
    overflow: hidden; }
    .gallery__item:nth-child(1n) {
      grid-area: small-1; }
    .gallery__item:nth-child(2n) {
      grid-area: small-2; }
    .gallery__item:nth-child(3n) {
      grid-area: large; }
    .gallery__item * {
      height: 100%;
      width: 100%; }
    .gallery__item img {
      width: 100%;
      object-fit: cover; }
  .gallery__more {
    position: absolute;
    right: 2rem;
    bottom: 2rem;
    padding-left: 0.75em;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1; }
    .gallery__more *,
    .gallery__more a {
      color: #FFF; }
    .gallery__more:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 1.5em;
      padding: 0 0.75em;
      background: #000;
      z-index: -1; }
  @media (max-width: 640px) {
    .gallery__list {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 8em 15em;
      grid-template-areas: "small-1 small-2" "large large"; }
    .gallery__more {
      right: 1rem;
      bottom: 1rem; } }

.header {
  --header-height: 7.5rem;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  height: var(--header-height);
  box-sizing: border-box;
  padding: 1rem;
  margin: 0;
  border-bottom: 2px solid #27509b;
  background: #FFF;
  z-index: 5;
  transition: 0.3s height; }
  .header__logo {
    display: block;
    width: 10em;
    transition: 0.3s width; }
    .header__logo img {
      display: block; }
  @media (min-width: 960px) {
    .header {
      padding: 1.5rem 3rem; } }
  @media (max-width: 959px) {
    .header {
      --header-height: 4rem; }
      .header__logo {
        width: 6em; } }
  .sticky-wrapper.is-sticky .header {
    --header-height: 4rem; }
    .sticky-wrapper.is-sticky .header__logo {
      width: 5em; }

.sticky-wrapper {
  height: auto !important; }

.info-btn {
  display: inline-block;
  padding: .5em .9em .5em 0;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
  line-height: 2em; }
  .info-btn:before {
    content: '';
    display: inline-block;
    position: relative;
    width: 2em;
    height: 2em;
    vertical-align: middle;
    margin-right: 0.6em;
    transform: translateY(-0.05em);
    transform-origin: center bottom; }
  .info-btn:hover:before {
    animation-name: bounce;
    animation-duration: 0.5s; }
  .info-btn, .info-btn--light {
    color: #000; }
    .info-btn:before, .info-btn--light:before {
      background: url("../assets/info-icon.svg") left center no-repeat; }
  .info-btn--dark {
    color: #FFF; }
    .info-btn--dark:before {
      background: url("../assets/info-icon.svg") left center no-repeat; }

.teaser .info-btn {
  display: none; }

.search-results {
  padding-bottom: 4rem; }
  .search-results-entry {
    display: flex; }
  .search-results-head {
    display: flex;
    justify-content: space-between; }
  .search-results-bottom {
    float: right; }
  .search-results-pages {
    list-style: none; }
    .search-results-pages .page {
      display: inline-block; }

.page .page-link {
  padding: 0 0.5rem;
  border-bottom: 1px solid #b5b5b5; }
  .page .page-link a {
    color: #b5b5b5; }

.page .current {
  background: #b5b5b5;
  color: #FFF; }

.result-number {
  left: 0;
  top: 0;
  font-size: 3em;
  font-weight: bold;
  line-height: 1em;
  width: 3rem;
  text-align: center;
  color: #b5b5b5;
  z-index: 0; }

.entry-headline {
  display: block;
  font-size: 1.5em;
  line-height: 1.2;
  margin-bottom: 3.75px;
  text-transform: uppercase;
  color: #000;
  border-bottom: 1px solid #b5b5b5; }

.result-content {
  display: flex;
  flex-basis: 100%;
  flex-direction: column; }

.entry-text {
  box-sizing: border-box;
  padding-top: 1rem; }

.form-group {
  padding: 1rem auto; }
  .form-group__search-input {
    width: 15rem;
    height: 2rem;
    margin-top: 2rem;
    border: 0;
    border-bottom: 1px solid #b5b5b5;
    background-color: #b5b5b538;
    outline: none; }
    .form-group__search-input::placeholder {
      padding-left: 1rem; }
  .form-group__search-submit {
    display: none; }

.main-nav__list {
  text-transform: uppercase;
  list-style: none;
  margin: 0;
  padding: 0;
  list-style-image: none; }

.main-nav__item--level-0 > a,
.main-nav__item--level-0 > span {
  font-size: 1.2em;
  font-weight: bold;
  color: #000;
  text-decoration: none;
  padding: 0 1.3em;
  transition: 0.2s; }
  .main-nav__item--level-0 > a:after,
  .main-nav__item--level-0 > span:after {
    display: none; }
    .header:hover .main-nav__item--level-0 > a:after, .header:hover
    .main-nav__item--level-0 > span:after {
      display: block; }
  .main-nav__item--level-0 > a:hover,
  .main-nav__item--level-0 > span:hover {
    color: #27509b; }

.main-nav__item--level-1 a, .main-nav__item--level-1 span {
  opacity: .6;
  color: #FFF;
  transition: .2s; }
  .main-nav__item--level-1 a:hover, .main-nav__item--level-1 span:hover {
    opacity: 1; }

.main-nav__drop {
  background: #27509b; }

@media (min-width: 960px) {
  .main-nav {
    width: auto;
    margin-right: 4rem; }
    .main-nav__drop {
      display: none;
      position: absolute;
      left: 0;
      top: var(--header-height);
      width: 100%;
      padding: 1rem;
      box-sizing: border-box; }
      .header:hover .main-nav__drop {
        display: block; }
    .main-nav__list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap; }
      .main-nav__list--level-1 {
        justify-content: flex-start;
        position: relative;
        margin: 0 auto;
        min-width: 240px;
        max-width: 1000px; }
    .main-nav__item--level-0.main-nav__item--parent > a,
    .main-nav__item--level-0.main-nav__item--parent > span {
      position: relative; }
      .main-nav__item--level-0.main-nav__item--parent > a:after,
      .main-nav__item--level-0.main-nav__item--parent > span:after {
        position: absolute;
        content: "";
        border-style: solid solid solid dashed;
        border-color: currentColor transparent;
        border-width: 1.4em 1.4em 0 0;
        left: 50%;
        top: calc(var(--header-height)/2 - 1px);
        transform: translate(-50%, 0) rotate(45deg);
        color: #27509b;
        opacity: 0;
        transition: 0.4s ease;
        transition-delay: 0.2s; }
      .main-nav__item--level-0.main-nav__item--parent > a:hover,
      .main-nav__item--level-0.main-nav__item--parent > span:hover {
        color: #27509b; }
    .main-nav__item--level-0.main-nav__item--parent:hover {
      z-index: 2; }
      .main-nav__item--level-0.main-nav__item--parent:hover > a,
      .main-nav__item--level-0.main-nav__item--parent:hover > span {
        cursor: pointer; }
        .main-nav__item--level-0.main-nav__item--parent:hover > a:before,
        .main-nav__item--level-0.main-nav__item--parent:hover > span:before {
          display: block;
          position: absolute;
          content: "";
          top: 1.25em;
          transform: skewY(-5deg);
          right: 0;
          width: 200%;
          height: calc(var(--header-height)); }
        .main-nav__item--level-0.main-nav__item--parent:hover > a:after,
        .main-nav__item--level-0.main-nav__item--parent:hover > span:after {
          opacity: 1;
          transition: opacity 0.2s ease-in; }
        .main-nav__item--level-0.main-nav__item--parent:hover > a ~ .main-nav__drop,
        .main-nav__item--level-0.main-nav__item--parent:hover > span ~ .main-nav__drop {
          transition: 0.4s;
          transition-delay: 0.2s ease;
          z-index: 2; }
          .main-nav__item--level-0.main-nav__item--parent:hover > a ~ .main-nav__drop .main-nav__item--level-1,
          .main-nav__item--level-0.main-nav__item--parent:hover > span ~ .main-nav__drop .main-nav__item--level-1 {
            opacity: 1;
            transform: translateX(0);
            transition: .2s;
            transition-delay: 0.4s; }
            .main-nav__item--level-0.main-nav__item--parent:hover > a ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(1),
            .main-nav__item--level-0.main-nav__item--parent:hover > span ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(1) {
              transition-delay: calc(0.4s + 0.05s * 1); }
            .main-nav__item--level-0.main-nav__item--parent:hover > a ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(2),
            .main-nav__item--level-0.main-nav__item--parent:hover > span ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(2) {
              transition-delay: calc(0.4s + 0.05s * 2); }
            .main-nav__item--level-0.main-nav__item--parent:hover > a ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(3),
            .main-nav__item--level-0.main-nav__item--parent:hover > span ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(3) {
              transition-delay: calc(0.4s + 0.05s * 3); }
            .main-nav__item--level-0.main-nav__item--parent:hover > a ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(4),
            .main-nav__item--level-0.main-nav__item--parent:hover > span ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(4) {
              transition-delay: calc(0.4s + 0.05s * 4); }
            .main-nav__item--level-0.main-nav__item--parent:hover > a ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(5),
            .main-nav__item--level-0.main-nav__item--parent:hover > span ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(5) {
              transition-delay: calc(0.4s + 0.05s * 5); }
            .main-nav__item--level-0.main-nav__item--parent:hover > a ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(6),
            .main-nav__item--level-0.main-nav__item--parent:hover > span ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(6) {
              transition-delay: calc(0.4s + 0.05s * 6); }
            .main-nav__item--level-0.main-nav__item--parent:hover > a ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(7),
            .main-nav__item--level-0.main-nav__item--parent:hover > span ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(7) {
              transition-delay: calc(0.4s + 0.05s * 7); }
            .main-nav__item--level-0.main-nav__item--parent:hover > a ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(8),
            .main-nav__item--level-0.main-nav__item--parent:hover > span ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(8) {
              transition-delay: calc(0.4s + 0.05s * 8); }
            .main-nav__item--level-0.main-nav__item--parent:hover > a ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(9),
            .main-nav__item--level-0.main-nav__item--parent:hover > span ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(9) {
              transition-delay: calc(0.4s + 0.05s * 9); }
            .main-nav__item--level-0.main-nav__item--parent:hover > a ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(10),
            .main-nav__item--level-0.main-nav__item--parent:hover > span ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(10) {
              transition-delay: calc(0.4s + 0.05s * 10); }
            .main-nav__item--level-0.main-nav__item--parent:hover > a ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(11),
            .main-nav__item--level-0.main-nav__item--parent:hover > span ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(11) {
              transition-delay: calc(0.4s + 0.05s * 11); }
            .main-nav__item--level-0.main-nav__item--parent:hover > a ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(12),
            .main-nav__item--level-0.main-nav__item--parent:hover > span ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(12) {
              transition-delay: calc(0.4s + 0.05s * 12); }
            .main-nav__item--level-0.main-nav__item--parent:hover > a ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(13),
            .main-nav__item--level-0.main-nav__item--parent:hover > span ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(13) {
              transition-delay: calc(0.4s + 0.05s * 13); }
            .main-nav__item--level-0.main-nav__item--parent:hover > a ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(14),
            .main-nav__item--level-0.main-nav__item--parent:hover > span ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(14) {
              transition-delay: calc(0.4s + 0.05s * 14); }
            .main-nav__item--level-0.main-nav__item--parent:hover > a ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(15),
            .main-nav__item--level-0.main-nav__item--parent:hover > span ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(15) {
              transition-delay: calc(0.4s + 0.05s * 15); }
            .main-nav__item--level-0.main-nav__item--parent:hover > a ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(16),
            .main-nav__item--level-0.main-nav__item--parent:hover > span ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(16) {
              transition-delay: calc(0.4s + 0.05s * 16); }
            .main-nav__item--level-0.main-nav__item--parent:hover > a ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(17),
            .main-nav__item--level-0.main-nav__item--parent:hover > span ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(17) {
              transition-delay: calc(0.4s + 0.05s * 17); }
            .main-nav__item--level-0.main-nav__item--parent:hover > a ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(18),
            .main-nav__item--level-0.main-nav__item--parent:hover > span ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(18) {
              transition-delay: calc(0.4s + 0.05s * 18); }
            .main-nav__item--level-0.main-nav__item--parent:hover > a ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(19),
            .main-nav__item--level-0.main-nav__item--parent:hover > span ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(19) {
              transition-delay: calc(0.4s + 0.05s * 19); }
            .main-nav__item--level-0.main-nav__item--parent:hover > a ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(20),
            .main-nav__item--level-0.main-nav__item--parent:hover > span ~ .main-nav__drop .main-nav__item--level-1:nth-of-type(20) {
              transition-delay: calc(0.4s + 0.05s * 20); }
    .main-nav__item--level-0:not(:hover) > a:after,
    .main-nav__item--level-0:not(:hover) > span:after {
      opacity: 0;
      transition: 0.2s;
      transition-delay: 0.2s; }
    .main-nav__item--level-0:not(:hover) .main-nav__drop {
      visibility: visible;
      opacity: 0;
      transition: 0.4s;
      transition-delay: 0.4s;
      z-index: 1; }
      .main-nav__item--level-0:not(:hover) .main-nav__drop .main-nav__item--level-1 {
        transform: translateX(3em);
        opacity: 0;
        transition: 0.4s;
        transition-delay: 0.4s; }
    .main-nav__item--level-0.main-nav__item--active > a,
    .main-nav__item--level-0.main-nav__item--active > span {
      color: #6c6c6c; }
    .main-nav__item--level-0:first-of-type.main-nav__item--parent:hover > a:before {
      right: 0;
      left: auto;
      top: 0;
      transform: none;
      width: calc(50vw - 10em + 100%);
      height: calc(var(--header-height)*.75); }
    .main-nav__item--level-1 {
      transform: translateX(3em);
      opacity: 0;
      margin: 1rem;
      text-align: center; }
      .main-nav__item--level-1.main-nav__item--active > a,
      .main-nav__item--level-1.main-nav__item--active > span {
        opacity: 1; } }

@media (max-width: 959px) {
  .main-nav {
    width: 80vw;
    max-width: 350px;
    font-size: .85em;
    letter-spacing: .075em; }
    .main-nav__list--level-0 {
      border-bottom: 2px #4c74bc solid; }
    .main-nav__list--level-1 {
      padding: 0.5rem 0; }
    .main-nav__item {
      position: relative; }
      .main-nav__item > a,
      .main-nav__item > span {
        display: block;
        position: relative;
        z-index: 1;
        font-weight: normal;
        color: #FFF; }
      .main-nav__item--level-0 {
        border-top: 2px #4c74bc solid; }
        .main-nav__item--level-0 > a,
        .main-nav__item--level-0 > span {
          padding: 0.5rem 2rem 0.5rem 1rem;
          z-index: 3; }
          .main-nav__item--level-0 > a:hover,
          .main-nav__item--level-0 > span:hover {
            color: #FFF;
            opacity: 0.6;
            cursor: pointer; }
      .main-nav__item--level-1 {
        animation: nav-item-in .3s ease both;
        font-size: .95em; }
        .main-nav__item--level-1:nth-of-type(1) {
          animation-delay: calc(0.05s * 1); }
        .main-nav__item--level-1:nth-of-type(2) {
          animation-delay: calc(0.05s * 2); }
        .main-nav__item--level-1:nth-of-type(3) {
          animation-delay: calc(0.05s * 3); }
        .main-nav__item--level-1:nth-of-type(4) {
          animation-delay: calc(0.05s * 4); }
        .main-nav__item--level-1:nth-of-type(5) {
          animation-delay: calc(0.05s * 5); }
        .main-nav__item--level-1:nth-of-type(6) {
          animation-delay: calc(0.05s * 6); }
        .main-nav__item--level-1:nth-of-type(7) {
          animation-delay: calc(0.05s * 7); }
        .main-nav__item--level-1:nth-of-type(8) {
          animation-delay: calc(0.05s * 8); }
        .main-nav__item--level-1:nth-of-type(9) {
          animation-delay: calc(0.05s * 9); }
        .main-nav__item--level-1:nth-of-type(10) {
          animation-delay: calc(0.05s * 10); }
        .main-nav__item--level-1:nth-of-type(11) {
          animation-delay: calc(0.05s * 11); }
        .main-nav__item--level-1:nth-of-type(12) {
          animation-delay: calc(0.05s * 12); }
        .main-nav__item--level-1:nth-of-type(13) {
          animation-delay: calc(0.05s * 13); }
        .main-nav__item--level-1:nth-of-type(14) {
          animation-delay: calc(0.05s * 14); }
        .main-nav__item--level-1:nth-of-type(15) {
          animation-delay: calc(0.05s * 15); }
        .main-nav__item--level-1:nth-of-type(16) {
          animation-delay: calc(0.05s * 16); }
        .main-nav__item--level-1:nth-of-type(17) {
          animation-delay: calc(0.05s * 17); }
        .main-nav__item--level-1:nth-of-type(18) {
          animation-delay: calc(0.05s * 18); }
        .main-nav__item--level-1:nth-of-type(19) {
          animation-delay: calc(0.05s * 19); }
        .main-nav__item--level-1:nth-of-type(20) {
          animation-delay: calc(0.05s * 20); }
        .main-nav__item--level-1 > a {
          padding: 0.25rem 1rem; }
      .main-nav__item--active {
        background: #1f4384; }
    .main-nav__drop {
      z-index: 0;
      position: relative; }
      .main-nav__drop:before {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateY(-100%);
        width: 100%;
        height: 2.5rem;
        background: #1f4384;
        z-index: -1; } }

@keyframes nav-item-in {
  from {
    opacity: 0;
    transform: translateX(3em); } }

@keyframes nav-bar-in {
  from {
    visibility: hidden;
    transform: translateY(-1em); } }

@keyframes arrow-in {
  from {
    visibility: hidden;
    transform: translate(-50%, -1em) rotate(45deg); }
  to {
    visibility: visible;
    transform: translate(-50%, 0) rotate(45deg); } }

.meta-bar {
  padding: 1rem 0;
  background: #e7e9eb;
  position: relative;
  left: 50%;
  right: 50%;
  width: 100vw;
  margin-left: -50vw;
  margin-right: -50vw; }
  .meta-bar__list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    list-style-image: none; }
  .meta-bar__item + .meta-bar__item {
    margin-left: 2rem; }
  .meta-bar__item:last-child {
    flex-grow: 1; }
  @media (max-width: 640px) {
    .meta-bar__list {
      flex-direction: column; }
    .meta-bar__item + .meta-bar__item {
      margin-left: 0;
      margin-top: 1rem; } }

.more-btn {
  display: inline-block;
  padding: .5em .9em .5em 0;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
  line-height: 2em; }
  .more-btn:before {
    content: '';
    display: inline-block;
    position: relative;
    width: 2em;
    height: 2em;
    vertical-align: middle;
    margin-right: 0.6em;
    transform: translateY(-0.05em);
    transform-origin: center bottom; }
  .more-btn:hover:before {
    animation-name: bounce;
    animation-duration: 0.5s; }
  .more-btn, .more-btn--light {
    color: #000; }
    .more-btn:before, .more-btn--light:before {
      background: url("../assets/more-icon.svg") left center no-repeat; }
  .more-btn--dark {
    color: #FFF; }
    .more-btn--dark:before {
      background: url("../assets/more-icon.svg") left center no-repeat; }

.more-link-btn {
  display: inline-block;
  padding: .5em .9em .5em 0;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
  line-height: 2em; }
  .more-link-btn:before {
    content: '';
    display: inline-block;
    position: relative;
    width: 2em;
    height: 2em;
    vertical-align: middle;
    margin-right: 0.6em;
    transform: translateY(-0.05em);
    transform-origin: center bottom; }
  .more-link-btn:hover:before {
    animation-name: bounce;
    animation-duration: 0.5s; }
  .more-link-btn, .more-link-btn--light {
    color: #000; }
    .more-link-btn:before, .more-link-btn--light:before {
      background: url("../assets/eye-icon.svg") left center no-repeat; }
  .more-link-btn--dark {
    color: #FFF; }
    .more-link-btn--dark:before {
      background: url("../assets/eye-icon.svg") left center no-repeat; }

.nav {
  display: flex; }
  .nav__check {
    display: none; }
  .nav__search {
    position: relative;
    padding-left: 1rem;
    min-width: 1.5em;
    justify-self: flex-end;
    border-left: 2px solid #000; }
    .nav__search__input {
      width: 0;
      padding: 0.25rem;
      box-sizing: border-box;
      transition: width .5s ease, opacity .5s ease;
      border: none;
      opacity: 0; }
      .nav__search__input--active {
        width: 8em;
        color: inherit;
        opacity: 1; }
    .nav__search__submit {
      position: absolute;
      right: 0;
      top: 0;
      width: 1.9em;
      height: 1.5em;
      cursor: pointer;
      color: transparent;
      overflow: hidden;
      text-indent: -99em;
      border: none;
      background: url("../assets/search-icon.svg") right 0.25rem center no-repeat;
      background-size: 1.45em; }
  @media (min-width: 960px) {
    .nav__toggle {
      display: none; }
    .nav__menu {
      display: flex;
      align-items: center; }
    .nav__search__input--active {
      width: 10em; } }
  @media (max-width: 959px) {
    .nav__menu {
      position: absolute;
      right: 0;
      top: var(--header-height);
      height: calc(100vh - 2*var(--header-height));
      padding: 2.25rem 0;
      background: #27509b;
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch; }
    .nav__toggle {
      position: absolute;
      right: 0;
      top: 0;
      padding: 1em 1rem 1em 2rem;
      cursor: pointer;
      color: #FFF;
      z-index: 1;
      -webkit-tap-highlight-color: transparent; }
      .nav__toggle:before {
        content: '';
        background-image: url("../assets/dropdown.svg");
        background-size: contain;
        background-repeat: no-repeat;
        width: 1em;
        height: 1em;
        display: block;
        transform: rotate(0.25turn);
        opacity: 0.4;
        transition: 0.2s; }
      .nav__toggle--root {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        top: 0.25rem;
        width: 24px;
        height: 20px;
        padding: 0 1.5rem;
        color: #27509b;
        line-height: 0;
        font-weight: bold; }
        .nav__toggle--root:before {
          content: none; }
        .nav__toggle--root span {
          display: block;
          width: 100%;
          height: 2px;
          transition: all .25s ease;
          background-color: #000; }
    .nav__check:not(:checked) ~ div:first-of-type {
      display: none; }
    .nav__check:checked ~ .nav__toggle:before {
      transform: rotate(0.5turn); }
    .nav__check:checked ~ .nav__toggle--root {
      transform: none; }
      .nav__check:checked ~ .nav__toggle--root:after {
        position: absolute;
        content: "";
        border-style: solid solid solid dashed;
        border-color: currentColor transparent;
        border-width: 1em 1em 0 0;
        transform: translate(-2px, calc(var(--header-height)/2 + 2px)) rotate(45deg); }
      .nav__check:checked ~ .nav__toggle--root span:nth-child(1) {
        transform-origin: 100% 3px;
        transform: rotate(-45deg); }
      .nav__check:checked ~ .nav__toggle--root span:nth-child(2) {
        opacity: 0; }
      .nav__check:checked ~ .nav__toggle--root span:nth-child(3) {
        transform-origin: 100% -1px;
        transform: rotate(45deg); } }

.news-detail__media img {
  display: block; }

.news-detail__header {
  margin: 2rem 0; }

.news-detail__body {
  font-size: 1.2em;
  line-height: 1.5; }

.news-detail__footer {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem; }
  .news-detail__footer .news-backlink-wrap {
    display: none; }

.news-detail__date, .news-detail__next {
  font-weight: 600;
  color: #888; }

.news-detail__next {
  position: relative;
  left: -1.5em; }
  .news-detail__next:after {
    position: absolute;
    content: "";
    width: 0.5em;
    height: 0.5em;
    border: 1px currentColor solid;
    border-right: none;
    border-bottom: none;
    transform: translate(-0.25em, -0.4em) rotate(225deg);
    border-width: 2px;
    margin-left: 0.5em;
    transform: translateY(0.5em) rotate(135deg); }

.news-item {
  display: flex;
  flex-direction: column-reverse;
  position: relative; }
  .news-item__text {
    padding: 2rem;
    height: 100%;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.85);
    padding-bottom: 4rem; }
    .news-item__text h2 {
      font-weight: 600; }
  .news-item__subline {
    display: none;
    margin: 1rem 0 2rem;
    font-size: 2em;
    font-weight: 300; }
  .news-item__media {
    height: 100%; }
    .news-item__media img {
      object-fit: cover;
      background: rgba(255, 255, 255, 0.85);
      width: 100%;
      height: 100%;
      display: block; }
  .news-item__date {
    opacity: .5;
    margin-top: 1rem;
    font-weight: 600; }
  @media (max-width: 1200px) {
    .news-item__media img {
      object-fit: cover; } }
  @media (max-width: 959px) {
    .news-item__date {
      margin-top: 0.5rem;
      padding-left: 2rem; }
    .news-item__text {
      margin-top: 0; }
    .news-item__media {
      display: block; }
      .news-item__media img {
        object-fit: cover; } }
  @media (max-width: 500px) {
    .news-item__media img {
      height: calc(60vw); } }

.news-list {
  padding: 4rem 0;
  background: #e7e9eb;
  position: relative;
  left: 50%;
  right: 50%;
  width: 100vw;
  margin-left: -50vw;
  margin-right: -50vw;
  box-sizing: border-box; }
  @media (max-width: 1200px) {
    .news-list {
      padding: 4.5rem; } }
  @media (max-width: 959px) {
    .news-list {
      padding: 2.5rem; } }
  @media (max-width: 375px) {
    .news-list {
      padding: 1rem; } }
  .news-list__item + .news-list__item {
    margin-top: 4rem; }
  @media (min-width: 960px) {
    .news-list__item {
      max-width: 1000px;
      margin: 0 auto; } }

.news-slider {
  padding: 6rem 0;
  position: relative;
  left: 50%;
  right: 50%;
  width: 100vw;
  margin-left: -50vw;
  margin-right: -50vw;
  overflow: hidden; }
  .news-slider:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../assets/slider-bg.jpg");
    z-index: 0;
    animation: slide-zoom 10s ease-in-out infinite alternate; }
  @media (max-width: 1200px) {
    .news-slider {
      padding: 4.5rem 4.5rem 6rem; }
      .news-slider .slick-arrow {
        --arrow-size: 2em; }
      .news-slider .slick-next {
        right: 1rem; }
      .news-slider .slick-prev {
        left: 1rem; } }
  @media (max-width: 959px) {
    .news-slider {
      padding: 2.5rem 2.5rem 6rem; }
      .news-slider .slick-arrow {
        display: none !important; } }
  @media (max-width: 375px) {
    .news-slider {
      padding: 1rem 1rem 6rem; }
      .news-slider .slick-arrow {
        display: none; } }
  .news-slider__item {
    display: block !important;
    position: relative;
    min-width: 240px;
    max-width: 1000px;
    position: relative;
    margin: 0 auto; }
    @media (min-width: 960px) {
      .news-slider__item {
        max-height: 450px; } }
    .news-slider__item .news-item__date {
      display: none; }
  .news-slider__caption {
    height: 100%;
    box-sizing: border-box;
    padding: 2rem;
    opacity: .85;
    background-color: #FFF; }

.product-slider {
  padding: 2rem 0;
  background: #e7e9eb;
  position: relative;
  left: 50%;
  right: 50%;
  width: 100vw;
  margin-left: -50vw;
  margin-right: -50vw; }
  .product-slider__list {
    list-style: none;
    margin: 0;
    padding: 0;
    list-style-image: none; }
  .product-slider__item {
    display: flex !important;
    padding: 0 0.25rem;
    box-sizing: border-box; }
  .product-slider__slide {
    position: relative;
    padding: 1rem;
    text-align: center;
    background: #FFF;
    transition: background-color .15s ease; }
    .product-slider__slide:hover {
      background: #f5f6f7; }
  .product-slider__image {
    margin-bottom: 1rem;
    flex-grow: 1;
    object-fit: contain; }
  .product-slider__link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-indent: -99em; }
  .product-slider .slick-arrow {
    width: 2em;
    height: 4em;
    background: none; }
    .product-slider .slick-arrow:after {
      color: #FFF;
      transform: rotate(315deg); }
  .product-slider .slick-prev {
    left: 0.25rem;
    transform: translateX(calc(-100% + 1px)) translateY(-50%); }
    .product-slider .slick-prev:after {
      position: absolute;
      content: "";
      border-style: solid solid solid dashed;
      border-color: currentColor transparent;
      border-width: 2em 2em 0 0;
      left: 1em;
      transform: rotate(315deg); }
  .product-slider .slick-next {
    right: 0.25rem;
    transform: translateX(calc(100% - 1px)) translateY(-50%); }
    .product-slider .slick-next:after {
      position: absolute;
      content: "";
      border-style: solid solid solid dashed;
      border-color: currentColor transparent;
      border-width: 2em 2em 0 0;
      right: 1em;
      transform: rotate(135deg); }
  .product-slider .slick-track {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-items: stretch;
    justify-content: stretch;
    height: 100%;
    align-content: stretch; }
  .product-slider .slick-track > .slick-slide,
  .product-slider .slick-track > .slick-slide > div,
  .product-slider .slick-track > .slick-slide > div > li,
  .product-slider .slick-track > .slick-slide > div > li > div {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    height: auto;
    align-items: center; }
  @media (max-width: 640px) {
    .product-slider {
      box-sizing: border-box; }
      .product-slider__item {
        padding: 0; } }
  @media (max-width: 375px) {
    .product-slider {
      padding: 2rem; } }

@supports (-ms-ime-align: auto) {
  .product-slider__image {
    align-self: center; }
  .product-slider .slick-track > .slick-slide,
  .product-slider .slick-track > .slick-slide > div,
  .product-slider .slick-track > .slick-slide > div > li,
  .product-slider .slick-track > .slick-slide > div > li > div {
    align-items: stretch; } }

.products__traits {
  padding: 1rem 1.5rem;
  background: #f5f6f7; }

.products__list {
  list-style: none;
  margin: 0;
  padding: 0;
  list-style-image: none; }

.products__item {
  position: relative;
  padding: 2rem;
  background: #e7e9eb; }
  .products__item:nth-child(even) {
    background: #d9dcdf; }

.products__aside {
  display: flex;
  justify-content: flex-end; }

.products__image {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  max-width: 100%; }
  .products__image img {
    display: block;
    width: 100%;
    height: 100%; }

.products__labels {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 1rem;
  margin-right: -1rem;
  margin-top: -0.75rem; }

.products__info {
  margin-top: -3rem; }
  .products__info--link {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }

.plus-btn {
  display: inline-block;
  padding: .5em .9em .5em 0;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
  line-height: 2em;
  padding-right: 1rem; }
  .plus-btn:before {
    content: '';
    display: inline-block;
    position: relative;
    width: 2em;
    height: 2em;
    vertical-align: middle;
    margin-right: 0.6em;
    transform: translateY(-0.05em);
    transform-origin: center bottom; }
  .plus-btn:hover:before {
    animation-name: bounce;
    animation-duration: 0.5s; }
  .plus-btn, .plus-btn--light {
    color: #000; }
    .plus-btn:before, .plus-btn--light:before {
      background: url("../assets/plus-icon.svg") left center no-repeat; }
  .plus-btn--dark {
    color: #FFF; }
    .plus-btn--dark:before {
      background: url("../assets/plus-icon.svg") left center no-repeat; }
  .plus-btn:before, .plus-btn--light:before {
    background-size: 1.5em;
    background-position: left center;
    height: 2em; }

.quick-bar {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  background: #e7e9eb; }
  .footer .quick-bar {
    background: none;
    padding: 0.5rem 0; }
  .quick-bar__contacts {
    display: flex; }
    .quick-bar__contacts a {
      margin: 2px 10px; }
  .quick-bar__contact {
    width: 2em;
    transition: background-color .15s ease;
    background: #b5b5b5; }
    .quick-bar__contact + .quick-bar__contact {
      margin-left: 0.5rem; }
    .quick-bar__contact:hover {
      background: #000; }
    .quick-bar__contact img {
      display: block; }
  .quick-bar__langs {
    display: flex;
    align-items: center;
    margin-left: 1rem; }
  .quick-bar__lang {
    font-weight: 600;
    opacity: .4;
    color: #000; }
    .quick-bar__lang + .quick-bar__lang {
      margin-left: 0.5rem;
      padding-left: 0.5rem;
      border-left: 0.1em #b5b5b5 solid; }
    .quick-bar__lang--right {
      padding-right: 0.7em; }
    .quick-bar__lang--active {
      opacity: 1; }
  @media (min-width: 960px) {
    .quick-bar {
      padding-left: 3rem;
      padding-right: 3rem; } }

.request-btn {
  display: inline-block;
  padding: .5em .9em .5em 0;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
  line-height: 2em; }
  .request-btn:before {
    content: '';
    display: inline-block;
    position: relative;
    width: 2em;
    height: 2em;
    vertical-align: middle;
    margin-right: 0.6em;
    transform: translateY(-0.05em);
    transform-origin: center bottom; }
  .request-btn:hover:before {
    animation-name: bounce;
    animation-duration: 0.5s; }
  .request-btn, .request-btn--light {
    color: #000; }
    .request-btn:before, .request-btn--light:before {
      background: url("../assets/request-icon.svg") left center no-repeat; }
  .request-btn--dark {
    color: #FFF; }
    .request-btn--dark:before {
      background: url("../assets/request-icon.svg") left center no-repeat; }

.sensor-icon {
  display: inline-block;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: middle;
  background: url("../assets/sensor-icon.svg") left center no-repeat; }

.shop-btn {
  display: inline-block;
  padding: .5em .9em .5em 0;
  text-transform: uppercase;
  font-weight: 600;
  color: #000;
  line-height: 2em; }
  .shop-btn:before {
    content: '';
    display: inline-block;
    position: relative;
    width: 2em;
    height: 2em;
    vertical-align: middle;
    margin-right: 0.6em;
    transform: translateY(-0.05em);
    transform-origin: center bottom; }
  .shop-btn:hover:before {
    animation-name: bounce;
    animation-duration: 0.5s; }
  .shop-btn, .shop-btn--light {
    color: #000; }
    .shop-btn:before, .shop-btn--light:before {
      background: url("../assets/shop_black-icon.svg") left center no-repeat; }
  .shop-btn--dark {
    color: #FFF; }
    .shop-btn--dark:before {
      background: url("../assets/shop_white-icon.svg") left center no-repeat; }

.slider {
  max-height: 642px; }
  .slider button:focus {
    box-shadow: none; }
  .slider__item {
    display: block;
    position: relative;
    padding: 0;
    margin: 0; }
  .slider__caption {
    position: absolute;
    width: 50%;
    left: 20%;
    top: 50%;
    transform: translate(-20%, -50%);
    text-align: left;
    font-size: 1.5em;
    font-weight: 400;
    z-index: 1; }
    .slider__caption.light * {
      color: #000; }
    .slider__caption.light .btn {
      padding: 0.33rem 0.66rem 0.33rem; }
      .slider__caption.light .btn:after {
        background: url("../assets/icon-arrow.svg") right center no-repeat; }
    .slider__caption.dark * {
      color: #FFF; }
    .slider__caption p {
      line-height: 1.25; }
  .slider .btn {
    font-size: 0.75em;
    font-weight: 600;
    text-transform: uppercase; }
    .slider .btn.dark {
      color: #FFF; }
    .slider .btn.light {
      color: #000; }
    .slider .btn:after {
      content: '';
      display: inline-block;
      position: relative;
      vertical-align: middle;
      width: 2em;
      height: 1em;
      margin-left: 0.6em;
      transform: translateY(-0.05em);
      transform-origin: center bottom; }
    .slider .btn.dark:after {
      background: url("../assets/icon-arrow-light.svg") right center no-repeat; }
    .slider .btn.light:after {
      background: url("../assets/icon-arrow.svg") right center no-repeat; }
  .slider .slick-slide {
    margin-bottom: -6px; }
    .slider .slick-slide div, .slider .slick-slide figure, .slider .slick-slide img, .slider .slick-slide video {
      display: block !important; }
    .slider .slick-slide img {
      object-fit: cover; }
    .slider .slick-slide video {
      width: 100%; }
  .slider .slick-active .animation img {
    animation: slide-zoom 10s ease-in alternate forwards; }
  .slider .slick-arrow {
    top: 55%; }
  .slider .slick-prev {
    left: 1em; }
  .slider .slick-next {
    right: 1em; }
  @media (max-width: 640px) {
    .slider__caption {
      font-size: .75em; } }

@keyframes slide-zoom {
  to {
    transform: scale(1.1); } }

.slider.fullsize {
  padding: 0;
  position: relative;
  left: 50%;
  right: 50%;
  width: 100vw;
  margin-left: -50vw;
  margin-right: -50vw;
  overflow: hidden; }
  .slider.fullsize .slick-slide img {
    object-fit: cover;
    width: 100%; }

@media (max-width: 820px) {
  .slider__caption {
    width: 50%; }
  .slider.fullsize {
    max-height: 460px; }
    .slider.fullsize .slick-slide img {
      max-height: 460px; }
  .slider .slick-arrow {
    display: none !important; }
  .slider .btn {
    padding: 0 0.66rem; } }

@media (max-width: 390px) {
  .slider__caption {
    width: 80%;
    font-size: 14px;
    top: 10%;
    transform: translate(-20%, -10%); }
  .slider.fullsize {
    max-height: 360px; }
    .slider.fullsize .slick-slide img {
      max-height: 360px; } }

.solutions {
  display: flex;
  flex-direction: column-reverse; }
  .solutions__problems {
    padding: 5rem 0 3rem; }
  .solutions__problem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center; }
    .solutions__problem h3 {
      font-weight: 500; }
    .solutions__problem img:first-of-type {
      display: block;
      border: 1.5px solid #b5b5b5;
      border-radius: 50%;
      width: 10em;
      height: 10em;
      object-fit: cover;
      margin: 1rem 0; }
  .solutions__answers {
    padding: 3rem 0;
    box-sizing: border-box;
    background: #27509b;
    position: relative;
    left: 50%;
    right: 50%;
    width: 100vw;
    margin-left: -50vw;
    margin-right: -50vw; }
    .solutions__answers *,
    .solutions__answers a {
      color: #FFF; }
    .solutions__answers:after {
      position: absolute;
      content: "";
      border-style: solid solid solid dashed;
      border-color: currentColor transparent;
      border-width: 2em 2em 0 0;
      color: #27509b;
      left: 50%;
      bottom: 1px;
      transform: translate(-50%, 50%) rotate(225deg);
      line-height: 1.15; }
    .solutions__answers p {
      font-size: 2rem; }
      @media (max-width: 640px) {
        .solutions__answers p__answers {
          text-align: center; } }
  @media (min-width: 960px) {
    .solutions__answers {
      font-size: 1.5em;
      line-height: 1.5; } }
  @media (max-width: 640px) {
    .solutions__answers {
      text-align: center; } }
  @media (max-width: 959px) {
    .solutions__problem img:first-of-type {
      width: 10em;
      height: 10em; } }
  @media (max-width: 640px) {
    .solutions__problem {
      align-items: flex-start;
      min-height: 7rem;
      padding-left: 50%;
      border-bottom: none;
      position: relative;
      text-align: left; }
      .solutions__problem > a,
      .solutions__problem > img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-150%, -50%); }
      .solutions__problem img:first-of-type {
        width: 7em;
        height: 7em; }
    .solutions__answers p {
      line-height: initial; } }

.teaser {
  position: relative;
  left: 50%;
  right: 50%;
  width: 100vw;
  margin-left: -50vw;
  margin-right: -50vw; }
  .teaser, .teaser--light {
    background: #dfe0e2; }
  .teaser--dark {
    background: #000; }
    .teaser--dark *,
    .teaser--dark a {
      color: #FFF; }
  .teaser__image {
    position: relative;
    display: block;
    margin: 0 auto; }
    .teaser__image > * {
      display: block;
      margin: 0 auto; }
    .teaser__image--blur {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      overflow: hidden; }
      .teaser__image--blur > * {
        object-fit: cover;
        width: 100%;
        filter: blur(10px);
        transform: scale(1.1); }
    .teaser__image--fullsize > * {
      margin: 0;
      width: 100vw;
      max-width: none;
      object-fit: contain; }
  .teaser__content {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 2; }
    @media (min-width: 1000px) {
      .teaser__content {
        left: calc((100vw - 1000px)/2); } }
  .teaser__title {
    padding-top: 1rem; }
    .teaser__title h2 {
      font-weight: bold; }
  .teaser__actions {
    list-style: none;
    margin: 0;
    padding: 0;
    list-style-image: none;
    z-index: 2;
    pointer-events: all; }
  .teaser__link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-indent: -99rem;
    z-index: 1; }
  @media (min-width: 960px) {
    .teaser__title {
      padding: 4rem 0 1rem;
      font-size: 1.5em; } }
  @media (max-width: 640px) {
    .teaser__title {
      font-size: .75em; } }

.trait {
  display: inline-block;
  white-space: nowrap;
  margin: 0.5rem 0;
  text-transform: uppercase;
  font-weight: bold;
  color: #888;
  padding: 0 0.5rem; }
  .trait:before {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    content: attr(data-short);
    margin-right: 0.5rem;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    border: .1em currentColor solid; }

.variation {
  padding: 4rem 0;
  box-sizing: border-box;
  position: relative;
  left: 50%;
  right: 50%;
  width: 100vw;
  margin-left: -50vw;
  margin-right: -50vw; }
  .variation__content {
    padding: 2rem; }
  .variation, .variation--dark {
    background: #e7e9eb; }
    .variation .variation__content, .variation--dark .variation__content {
      background: #FFF; }
  .variation--light {
    background: #FFF; }
    .variation--light .variation__content {
      background: #e7e9eb; }

.quick-contact {
  position: fixed;
  right: 0;
  top: 80%;
  transform: translateY(-50%) translateX(100%);
  display: flex;
  flex-direction: column;
  background: #dfe0e2;
  z-index: 4;
  padding: 0.5rem;
  transition: .3s;
  width: 2rem; }
  .quick-contact--visible {
    transform: translateY(-50%) translateX(0%); }
  .quick-contact__item {
    background: #27509b;
    width: 100%;
    margin-bottom: 0.5rem; }
    .quick-contact__item img {
      display: block; }
    .quick-contact__item:hover {
      background: #000; }
  .quick-contact__close {
    color: #c20e1a;
    display: block;
    transition: .2s; }
    .quick-contact__close:hover {
      cursor: pointer;
      color: #000; }
    .quick-contact__close:before {
      display: block;
      content: '⨯';
      text-align: center;
      font-size: 2rem;
      line-height: 1;
      padding-bottom: 0.5rem; }

#faudi-clock {
  margin: 4rem;
  color: #333;
  text-align: center; }
  @media (max-width: 1200px) {
    #faudi-clock {
      margin: 1rem; } }
  @media (max-width: 375px) {
    #faudi-clock {
      margin: 0; } }
  #faudi-clock h2 {
    border-bottom: 5px solid #27509b;
    padding: 5px 0;
    letter-spacing: 3px;
    font-size: 4em; }
    @media (max-width: 1200px) {
      #faudi-clock h2 {
        font-size: 4em;
        margin: .5em 0; } }
    @media (max-width: 375px) {
      #faudi-clock h2 {
        margin: 0; } }
  #faudi-clock #clock-numbers {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center; }
  #faudi-clock.boxed {
    margin: 0;
    margin-top: 25%; }
    #faudi-clock.boxed .cd-box {
      width: 20%; }
      @media (max-width: 1200px) {
        #faudi-clock.boxed .cd-box {
          width: 30%; } }
      @media (max-width: 375px) {
        #faudi-clock.boxed .cd-box {
          width: 40%; } }
  #faudi-clock .cd-box {
    display: flex;
    flex-flow: column nowrap;
    width: 12%;
    flex-grow: 1;
    box-shadow: 5px 6px 9px 1px rgba(53, 53, 53, 0.5);
    margin: 15px 7px; }
    @media (max-width: 1200px) {
      #faudi-clock .cd-box {
        width: 30%; } }
    @media (max-width: 375px) {
      #faudi-clock .cd-box {
        width: 48%; } }
    #faudi-clock .cd-box .numbers {
      background: #FFFFFF;
      font-size: 2.5rem;
      margin-top: 50px;
      line-height: 2.5rem;
      font-weight: 700; }
    #faudi-clock .cd-box .timeString {
      background: #27509b;
      color: #FFFFFF;
      font-size: 1.5rem;
      padding: 10px;
      font-weight: 600; }
