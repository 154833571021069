.distributor-become-btn {
  @include icon-btn('../assets/distributor_become-icon.svg');
  display: block;
  background-size: 2em !important;
  background-position: left 0.5em center !important;
  background-color: $very-light-blue-color !important;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  padding-left: $gap/2;
  &:before {
    width: $gap*2;
    height: $gap*2;
  }
}
