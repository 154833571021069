.content {
  padding: 0 $gap*2;
  @include center();
  @include width-range();

  @media (max-width: $small-desktop-width) {
    padding: 0 $gap*4;
  }

  @media (max-width: $layout-xs-width) {
    padding: 0 $gap;
  }
}
