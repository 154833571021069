.nav {
  display: flex;

  &__check {
    display: none;
  }

  &__search {
    position: relative;
    padding-left: $gap;
    min-width: 1.5em;
    justify-self: flex-end;
    border-left: 2px solid $text-color;

    &__input {
      width: 0;
      padding: $gap/4;
      box-sizing: border-box;
      transition: width .5s ease, opacity .5s ease;
      border: none;
      opacity: 0;

      &--active {
        width: 8em;
        color: inherit;
        opacity: 1;
      }
    }
    &__submit {
      position: absolute;
      right: 0;
      top: 0;
      width: 1.9em;
      height: 1.5em;
      cursor: pointer;
      color: transparent;
      overflow: hidden;
      text-indent: -99em;
      border: none;
      background: url("../assets/search-icon.svg") right $gap/4 center no-repeat;
      background-size: 1.45em;
    }
  }

  @include media(desktop) {
    &__toggle { display: none; }
    &__menu {
      display: flex;
      align-items: center;
    }
    &__search {
      &__input {
        &--active {
          width: 10em;
        }
      }
    }
  }

  @include media(tablet) {

    $corner-size: .75em;
    $default-rotation: 225deg;

    &__menu {
      position: absolute;
      right: 0;
      top: var(--header-height);
      height: calc(100vh - 2*var(--header-height));
      padding: $gap*2.25 0;
      background: $faudi-color;
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
    }

    &__toggle {
      position: absolute;
      right: 0;
      top: 0;
      padding: 1em $gap 1em $gap*2;
      cursor: pointer;
      color: $bg-color;
      z-index: 1;
      -webkit-tap-highlight-color: transparent;

      @include dropdown() {
        transform: rotate(0.25turn);
        opacity: 0.4;
        transition: 0.2s;
      };

      &--root {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        top: $gap/4;
        width: 24px;
        height: 20px;

        padding: 0 $gap*1.5;
        color: $link-color;
        line-height: 0;
        font-weight: bold;

        &:before { content: none; }


        span {
          display: block;
          width: 100%;
          height: 2px;
          transition: all .25s ease;
          background-color: $text-color;
        }
      }
    }

    &__check {
      &:not(:checked) ~ div:first-of-type {
        display: none;
      }
      &:checked ~ .nav__toggle {

        &:before {
          transform: rotate(0.5turn);
        }

        &--root {
          transform: none;

          @include corner(1em, after) {
            transform: translate(-2px, calc(var(--header-height)/2 + 2px)) rotate(45deg);
          }

          span {
            &:nth-child(1) {
              transform-origin: 100% 3px;
              transform: rotate(-45deg);
            }
            &:nth-child(2) { opacity: 0; }
            &:nth-child(3) {
              transform-origin: 100% -1px;
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }
}
