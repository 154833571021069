.tippy-popper {
  $tippy-themename: 'faudi';
  $arrow-positions: (
    'top',
    'bottom',
    'left',
    'right'
  );

  $arrow-size: 10px;
  $tooltip-color: $cobalt-color;

  @each $position in $arrow-positions {
    margin-#{$position}: -20px;

    &[x-placement^=#{$position}-start] {
      @if ($position == 'top') or ($position == 'bottom') {
        left: 100px !important;
      }
    }

    &[x-placement^=#{$position}] {

      .tippy-tooltip.#{$tippy-themename}-theme {
        .tippy-arrow {
          border: $arrow-size solid transparent;
          border-#{$position}: $arrow-size solid $tooltip-color;
          #{$position}: 100%;

          @if ($position == 'top') or ($position == 'bottom') {
            left: 10px !important;
          }
        }
      }
    }
  }

  .tippy-tooltip.#{$tippy-themename}-theme {
    background: $tooltip-color;
    color: $bg-color;

    &[data-animatefill] {
      background-color: transparent
    }

    .tippy-backdrop {
      background: $tooltip-color;
    }
    .tippy-roundarrow {
      fill: $tooltip-color;
    }

  }
}
