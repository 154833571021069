.cc {
  &-window{
    position: fixed;
    display: flex;
    flex-direction: row;
    background: white;
    padding: 1em 1.8em;
    z-index: 10;
  } 
  &-banner {
    left: 0;
    right: 0;
    bottom: 0;
  }
  &-message {
    flex: 1;
  }
  &-link {
    opacity: .8;
    display: inline-block;
    padding: .2em;
  }
  &-btn {
    display: block;
    padding: .4em .8em;
    font-size: .9em;
    font-weight: 700;
    border-width: 2px;
    border-style: solid;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    min-width: 140px;
    margin-left: .5em;
    flex: 1;
    color: white;
    background-color: rgb(62,99, 117);
    border-color: transparent;
  }
}