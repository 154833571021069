.companies {
  &__traits {
    display: flex;
    align-items: center;
    padding: $gap $gap*2;
    background: lighten($very-light-blue-color, 5%);
    p {
      margin: 0;
      padding-right: $gap*2;
      color: $darker-grey-color;
      
      span {
        vertical-align: middle;
      }
    }

  }
  &__list {
    @include reset-list();
  }
  &__item {
    padding: $gap*2;
    background-color: rgb(239, 241, 244);
    margin-bottom: 0.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    & .grid__col {
      margin: 0 !important;
    }
  }
  &__info {
    p {
      margin: 0;
    }
  }
  &__aside {
    display: flex;
    justify-content: flex-end;
    position: relative;
  }
  &__logo {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    margin-top: -2rem;
    max-width: 150px;
    max-height: 150px;
    background: $bg-color;
    margin-right: 2.5rem;
    img {
      display: block;
      width: 100%;
      height: 100%;
      background: $bg-color;
    }
  }
  &__labels {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: -1rem;
    top: 0;

    & .filter-icon {
      margin-bottom: $gap;
    }
  }

  @include media(mobile) {
    .__traits {
      padding: $gap;
    }
    &__aside {
      justify-content: space-between;
      margin-bottom: $gap;
    }

  }
}
