.fancybox {
  display: inline-block;

  &[title] {
    display: block;
    position: relative;

    &:before {
      position: absolute;
      content: attr(title);
      text-transform: uppercase;
      bottom: 0;
      right: 0;
      pointer-events: none;
      font-weight: $title-font-weight;
      color: $dark-grey-color;
      font-size: 1.15em;
      background: url("../assets/zoom-icon.svg") no-repeat;
      background-color: $bg-color;
      background-size: 1.3em;
      background-position: right 0.4em center;
      padding: .5em 2em .5em 1em;
    }
  }

  &-content &-image {
    background: $bg-color;
  }
}
