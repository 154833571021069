@mixin icon-btn($icon-dark, $icon-light: $icon-dark) {

  display: inline-block;
  padding: .5em .9em .5em 0;
  text-transform: uppercase;
  font-weight: 600;
  color: $text-color;
  line-height: 2em;

  &:before {
    $button-icon-size: 2em;

    content: '';
    display: inline-block;
    position: relative;
    width: $button-icon-size;
    height: $button-icon-size;
    vertical-align: middle;
    margin-right: 0.6em;
    transform: translateY(-0.05em);
    transform-origin: center bottom;
  }

  &:hover {
    &:before {
      animation-name: bounce;
      animation-duration: 0.5s;
    }
  }

  &,
  &--light {
    color: $text-color;

    &:before {
      background: url($icon-dark) left center no-repeat;
    }

  }
  &--dark {
    color: $bg-color;

    &:before {
      background: url($icon-light) left center no-repeat;
    }
  }
}

$bounce-offset: 1em;

@keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    transform: translate3d(0, -0.05em, 0);
  }

  40%,
  43% {
    transform: translate3d(0, -$bounce-offset, 0);
  }

  70% {
    transform: translate3d(0, -$bounce-offset/2, 0);
  }

  90% {
    transform: translate3d(0, -$bounce-offset/8, 0);
  }
}

// change text color for sections with dark-ish background color
@mixin text-color($color) {
  *,
  a {
    color: $color;
  }
}

@mixin dropdown($size: 1em, $pseudo: before) {
  &:#{$pseudo} {
    content: '';
    background-image: url('../assets/dropdown.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: $size;
    height: $size;
    display: block;
    @content;
  }
}
