// typo3 specific styling

.content-full-width {
  position: relative;
  left: 50%;
  right: 50%;
  width: 100vw;
  margin-left: -50vw;
  margin-right: -50vw;

  @media (max-width: $small-desktop-width) {
    padding: 0 $gap*4.5;
  }

  @media (max-width: $layout-md-width) {
    padding: 0 $gap*2.5;
  }

  @media (max-width: $layout-xs-width) {
    padding: 0;
  }
}

// RTE font weight
.light {
  font-weight: 300;
}
.semibold {
  font-weight: 600;
}

// Layout options for RTEs
.frame-layout {
  &-2,
  &-3 {
    box-sizing: border-box;

    > section {
      padding: $gap*2;
    }
  }

  &-2 { // light
    background: $bg-color;
    > section {
      background: $very-light-blue-color;
    }
  }

  &-3 { // dark
    background: $very-light-blue-color;
    > section {
      background: $bg-color;
    }
  }

  &-2,
  &-3 { // light/dark + full-width
    &.content-full-width {
      > section {
        width: 100%;
        max-width: $page-width;
        margin: 0 auto;
        box-sizing: border-box;
      }
      @media (max-width: $layout-xs-width) {
        padding-left: $gap;
        padding-right: $gap;
      }
    }
  }

  &-3 { // dark + centered
    &.centered {
      padding-left: $gap*4;
      padding-right: $gap*4;

      @media (max-width: $layout-xs-width) {
        padding-left: $gap*2;
        padding-right: $gap*2;
      }
    }
  }

  &-4 { // grey
      background-color: $very-light-blue-color;
  }
}

// Text + Media
.ce-textpic {
  &, &.ce-intext {
    @include media(tablet) {
      .ce-gallery {
        float: none;
        margin: $gap/2 0;
      }
    }
  }

  > a {
    width: 100%;
    .ce-bodytext {
      width: 100%;
    }
  }

  .ce-column {
    width: 100%;
  }

  &.ce-center {
    .ce-gallery figure {
      margin: 0 auto;
    }
  }

  &.ce-nowrap {
    margin: -$gap*2;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;

    @media (max-width: 768px) {
      flex-wrap: wrap;
    }

    &.ce-right {
      flex-direction: row-reverse;
    }

    .ce-bodytext {
      flex: 1 0 auto;
      width: 50%;
      box-sizing: border-box;
      h2 {
        color: black;
        a {
          color: black;
          &:visited, &:hover, &:link {
            color: black;
            text-decoration: none;
          }
        }
      }
    }
    > a {
      .ce-bodytext {
        width: 100%;
      }
    }
    .ce-gallery {
      width: 50%;
      margin: 0;
      flex: 1 0 auto;

      .ce-row,
      .ce-column,
      figure,
      .fancybox,
      img {
        height: 100%;
      }

     @media (max-width: $layout-sm-width) {
        max-width: none;
        width: 100%;
        max-height: 40vw;

        .ce-row,
        .ce-column,
        figure,
        .fancybox,
        img {
          max-height: inherit;
          width: 100%;
          height: 100%;
        }
      }

      img {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-width: none;
      }



      @media (max-width: 500px) {
        max-height: 60vw;
      }
    }

    .ce-bodytext {
      padding: $gap*2;
    }
  }
}

.frame {
  &[class*="frame-space"],
  &[class*="frame-space"] {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.frame-space {
  &-before {
    &-extra-small { padding-top: 0.3em }
    &-small { padding-top: 2em }
    &-medium { padding-top: 3em }
    &-large { padding-top: 4em }
    &-extra-large { padding-top: 5em }
  }

  &-after {
    &-extra-small { padding-bottom: 0.3em }
    &-small { padding-bottom: 2em }
    &-medium { padding-bottom: 3em }
    &-large { padding-bottom: 4em }
    &-extra-large { padding-bottom: 5em }
  }
}

