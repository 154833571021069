.news-slider {
  padding: $gap*6 0;
  @include full-width();
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../assets/slider-bg.jpg');
    z-index: 0;

    animation: slide-zoom 10s ease-in-out infinite alternate;
  }



  @media (max-width: $small-desktop-width) {
    padding: $gap*4.5 $gap*4.5 $gap*6;

    .slick {
      &-arrow {
        --arrow-size: 2em;
      }
      &-next {
        right: 1rem;
      }
      &-prev {
        left: 1rem;
      }
    }
  }

  @include media(tablet) {
    padding: $gap*2.5 $gap*2.5 $gap*6;
    .slick-arrow {display: none !important;}
  }

  @media (max-width: $layout-xs-width) {
    padding: $gap $gap $gap*6;

    .slick-arrow {
      display: none;
    }
  }

  &__item {
    display: block !important;
    position: relative;
    @include width-range();
    @include center();

    @include media(desktop) {
        max-height: 450px;
    }

    .news-item__date {
      display: none;
    }
  }

  &__caption {
    height: 100%;
    box-sizing: border-box;
    padding: $gap*2;
    opacity: .85;
    background-color: $bg-color;
  }
}
