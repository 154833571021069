.news-item {
  display: flex;
  flex-direction: column-reverse;
  position: relative;

  &__text {
    padding: $gap*2;
    height: 100%;
    box-sizing: border-box;
    background: transparentize($bg-color, .15);
    padding-bottom: $gap*4; // extra padding for more button compensation

    h2 {
      font-weight: 600;
    }
  }
  &__subline {
    display: none;
    margin: $gap 0 $gap*2;
    font-size: 2em;
    font-weight: 300;
  }
  &__media {
    height: 100%;

    img {
      object-fit: cover;
      background: transparentize($bg-color, .15);
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  &__date {
    opacity: .5;
    margin-top: $gap;
    font-weight: 600;
  }

  @media (max-width: $small-desktop-width) {
    &__media {
      img {
        object-fit: cover;
      }
    }
  }

  @include media(tablet) {
    &__date {
      margin-top: $gap/2;
      padding-left: $grid-gap;
    }

    &__text {
      margin-top: 0;
    }

    &__media {
      display: block;
      img {
        object-fit: cover;
      }
    }
  }

  @media (max-width: 500px) {
    &__media {
      img {
        height: calc(60vw);
      }
    }
  }


}
