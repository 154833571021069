.collapsible-country {
  &__list {
    @include reset-list();
  }
  
  & + & {
    margin-top: $gap/2;
  }

  &__item {
    position: relative;

    & + & {
      margin-top: $gap/2;
    }

    &--active .collapsible-country__head {
      &:before {
        transform: rotate(0.5turn);
      }
    }
  }
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    cursor: pointer;
    background: $very-light-blue-color;

    @include dropdown(1.5em) {
      transform: rotate(0.25turn);
      opacity: 0.4;
      transition: 0.2s;
      background-image: url('../assets/dropdown-dark.svg');
      position: absolute;
      right: 1em;
    };
  }
  &__body {}
  &__title {
    padding: $gap $gap*2;
    font-weight: 500;
    h2{
    font-size: 1em;
      color: rgb(101, 101, 101);
    }
  }
  &__preview {
    width: 15em;
    overflow: hidden;

    img {
      display: block;
      animation: slide-zoom 10s ease-in-out infinite alternate;
    }
  }

  @include media(desktop) {
    &__item {
      &:after {
        font-size: 2em;
      }
    }
  }

  @include media(mobile) {
    &__title {
      padding: $gap;
      font-size: $gap*0.8;
    }
    &__preview {
      display: none;
    }
    &__item {
    }
  }

  @keyframes slide-zoom {
    to { transform: scale(1.2); }
  }
}
