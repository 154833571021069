.trait {
  display: inline-block;
  white-space: nowrap;
  margin: $gap/2 0;
  text-transform: uppercase;
  font-weight: bold;
  color: $dark-grey-color;
  padding: 0 $gap/2;

  &:before {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    content: attr(data-short);
    margin-right: $gap/2;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    border: .1em currentColor solid;
  }
}
