.footer {
  @include text-color($bg-color);
  display: flex;
  flex-direction: column;
  background: $cobalt-color;

  &__info {
    padding: $gap*4 0 $gap*2;
    background: $dark-slate-blue-color;
  }

  &__claim {
    font-size: 4em;
    line-height: 1.2;
  }

  &__image {
    text-align: center;
  }

  &__contact {
    padding: $gap*6 0;
    background: $faudi-color;
    &__form {
      margin-top: $gap*2;
      .powermail_textarea {
        resize: none;
      }
    }
    form {
      background: $faudi-color;
    }

    &__address,
    &__options {
      position: relative;
      font-style: normal;
      line-height: 2;
      &:before {
        position: absolute;
        content: "";
        margin-top: -2em;
        width: 1.5em;
        height: 1.5em;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: .2;
      }
    }

    &__address {
      top: -1rem;
      &:before { background-image: url('../assets/location-icon-white.svg'); }
    }
    &__options {
      margin-top: $gap*5;
      &:before { background-image: url('../assets/contact-icon-white.svg'); }
    }
  }
  &__nav {
    padding: 0 0 $gap*4;
    background: $faudi-color;

    &__list {
      @include reset-list();
      padding-top: $gap*4;
      border-top: .1em $cool-blue-color solid;
    }

    &__item {
      margin-bottom: $gap;
      a {
        display: block;
        opacity: .4;
        text-decoration: none;
        &:hover {
          opacity: 1;
        }
        &:before { content: "· "; }
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    padding: 0 $gap*2;
    box-sizing: border-box;
    width: 100%;
    @include width-range();
    @include center();
  }

  &__copy {
    padding: $gap 0;
    font-size: .85em;
  }

  @include media(desktop) {
    &__nav {
      &__list {
        column-count: 3;
        column-gap: $gap;
      }
    }
  }
  @include media(mobile) {
    &__claim {
      font-size: 3em;
    }

    &__image {
      &--mobile {
        display: flex;
      }
    }
    &__nav {
      &__list {
        display: flex;
        flex-wrap: wrap;
      }
      &__item {
        width: 50%;
      }
    }
  }
}

