@import "vars";

/*==========================================================================
  basic components
  ==========================================================================*/

.btn {
  display: inline-block;
  padding:  $button-padding*$padding-top-multiplier
    $button-padding*2
  $button-padding;

  color: $bg-color;
  background: $link-color;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  outline: 0;

  &:hover,
  &:focus {
    color: $bg-color;
    background: $highlight-color;
    text-decoration: none;
  }
}


/* Video Trigger */

.video_trigger {
  padding: 2rem;
  background: #e7e9eb;
  text-align: center;

  &:before {
    content: '';
    display: inline-block;
    position: relative;
    width: 100%;
    height: 5em;
    vertical-align: middle;
    margin-right: 0.6em;
    background: url('../assets/youtube-icon-red.svg') no-repeat center;
    background-size: 15rem;
  }

  p {
    width: 80%;
    margin: 2rem auto;
  }
}

.ce-gallery figcaption.video-caption {
  display: none;
}

iframe[data-scaling="true"] {
  min-width: 100%;
  max-width: 100%;
}
