@import "vars";

/*==========================================================================
  mixins
  ==========================================================================*/

@mixin width-range($min: $page-min-width, $max: $page-max-width) {
  min-width: $min;
  max-width: $max;
}

@mixin center() {
  position: relative;
  margin: 0 auto;
}

@mixin clearfix() {
  &:before,
  &:after {
    display: table;
    content: "";
    clear: both;
  }
}

@mixin reset-list() {
  list-style: none;
  margin: 0;
  padding: 0;
  list-style-image: none; // fix ie list style
}

// ^
@mixin caret($size: 1em, $pseudo: after) {
  &:#{$pseudo} {
    position: absolute;
    content: "";
    width: $size;
    height: $size;
    border: 1px currentColor solid;
    border-right: none;
    border-bottom: none;
    transform: translate(-$size/2, -$size/1.25) rotate(225deg);
    @content;
  }
}

// ▲
@mixin corner($size: 1em, $pseudo: after) {
  &:#{$pseudo} {
    position: absolute;
    content: "";
    border-style: solid solid solid dashed;
    border-color: currentColor transparent;
    border-width: $size $size 0 0;
    @content;
  }
}

@mixin text-outline($color: $highlight-color, $size: 1px) {
  text-shadow:
    -$size -$size 0 $color,
    $size -$size 0 $color,
    -$size $size 0 $color,
    $size $size 0 $color;
}

@mixin h-gradient($col1: $bg-color, $col2: $highlight-color, $start: 0%, $end: 100%) {
  background: $col1; /* Old browsers */
  background: linear-gradient(to right, $col1 $start,$col2 $end);
}
@mixin v-gradient($col1: $bg-color, $col2: $highlight-color, $start: 0%, $end: 100%) {
  background: $col1; /* Old browsers */
  background: linear-gradient(to bottom, $col1 $start,$col2 $end);
}

@mixin font-face($font-name, $font-file, $weight: normal, $style: normal, $variant: normal) {
  @font-face {
    font-family: $font-name;
    src: url('#{$fonts}#{$font-file}.woff2');
    src: local('☺'),
    url('#{$fonts}#{$font-file}.woff') format('woff'),
    url('#{$fonts}#{$font-file}.eot') format('embedded-opentype'),
    url('#{$fonts}#{$font-file}.eot?#iefix') format('embedded-opentype'),
    url('#{$fonts}#{$font-file}.ttf') format('truetype'),
    url('#{$fonts}#{$font-file}.svg') format('svg');
    font-weight: $weight;
    font-style: $style;
    font-variant: $variant;
  }
}

@mixin grid-spacing($gap) {
  margin-left: -$gap/2;
  margin-right: -$gap/2;

  > .grid__col {
    padding: 0 $gap/2;
  }
}

@mixin grid-v-spacing($gap) {
  margin-top: -$gap;

  > .grid__col {
    margin-top: $gap !important;
  }
}

@mixin grid-col-sizes($suffix, $n) {
  @for $i from 1 through $n {
    &#{$suffix}#{$i} {
      $width: (100% / $n * $i);
      max-width: $width;
      flex: 0 0 $width;

      .no-flexbox & {
        width: $width;
        max-width: none;

        $j: floor($n / $i);
        $k: $j + 1;
        &:nth-child(#{$j}n + #{$k}) {
          clear: both;
        }
      }
    }
  }
}

@mixin grid-responsive() {
  > .grid__col {
    &:not([class*="--sm-"]) {
      display: block;
      width: 100%;
      max-width: 100%; // ie > 9 needs 100% instead of none
      flex-basis: 100%;
      margin: $responsive-grid-margin 0 0;
      &:first-child { margin-top: 0; }
    }
  }
}

@mixin full-width() {
  position: relative;
  left: 50%;
  right: 50%;
  width: 100vw;
  margin-left: -50vw;
  margin-right: -50vw;
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@mixin font-size($size, $unit: 1em) {
  font-size: strip-unit($size / $default-font-size) * $unit;
}

@mixin media($media) {
  @if $media == desktop {
    @media (min-width: ($layout-md-width + 1)) { @content; }
  }
  @else if $media == tablet {
    @media (max-width: ($layout-md-width)) { @content; }
  }
  @else if $media == mobile {
    @media (max-width: ($layout-sm-width)) { @content; }
  }
  @else if $media == ie {
    @media all and (-ms-high-contrast: none) { @content; }
  }
}

@mixin use-iconfont($iconfont-name) {
  [class^="icon-"],
  [class*=" icon-"],
  [data-icon] {
    &:before {
      display: inline-block;
      padding-right: $icon-margin;
      speak: none;
      font-family: $iconfont-name;
      font-size: $icon-size;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;

      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    &:only-child,
    &.icon--only {
      &:before {
        padding-right: 0;
      }
    }
    &:hover {
      &:before {
        text-decoration: none !important;
      }
    }
  }
  [data-icon]:before {
    content: attr(data-icon);
  }
}
