.quick-contact {
  position: fixed;
  right: 0;
  top: 80%;
  transform: translateY(-50%) translateX(100%);
  display: flex;
  flex-direction: column;
  background: $lighter-grey-color;
  z-index: 4;
  padding: 0.5rem;
  transition: .3s;
  width: 2rem;

  &--visible {
    transform: translateY(-50%) translateX(0%);
  }

  &__item {
    background: $faudi-color;
    width: 100%;
    margin-bottom: 0.5rem;

    img {
      display: block;
    }

    &:hover {
      background: $text-color;
    }
  }
  &__close {
    color: $faudi-red-color;
    display: block;
    transition: .2s;
    &:hover {
      cursor: pointer;
      color: $text-color;
    }
    &:before {
      display: block;
      content: '⨯';
      text-align: center;
      font-size: 2rem;
      line-height: 1;
      padding-bottom: 0.5rem;
    }
  }
}
