// DO NOT CHANGE THESE VALUES!
// overwrite in your own 'config.scss' instead

$images: '../images/' !default; // usage: url('#{$images}file.jpg')
$fonts: '../fonts/' !default; // usage: url('#{$fonts}file.eot')

$main-font: sans-serif !default;
$title-font: serif !default;

$main-font-weight: normal !default;
$title-font-weight: 700 !default;

$theme-color: tomato !default;
$text-color: #666 !default;
$title-color: #333 !default;
$link-color: $theme-color !default;
$highlight-color: darken($link-color, 20%) !default;
$bg-color: #FFF !default;

$default-font-size: 16px !default;
$default-line-height: 1.5em !default;
$icon-size: 0.9em !default;
$border-size: 2px !default;

$gap: 1rem !default;

$grid-col-sizes: 12 !default;
$grid-gap: $gap*2 !default;

$responsive-grid-margin: $grid-gap !default; // for collapsed grid columns only

$button-padding: $gap*.33 !default;
$icon-margin: $gap*.33 !default;
$padding-top-multiplier: .9 !default;

$default-easing: cubic-bezier(.5,.2,.5,1) !default;

$page-width: 1160px !default;
$page-min-width: 320px !default;
$page-max-width: $page-width !default;

$layout-sm-width: (480px - 1) !default;
$layout-md-width: (960px - 1) !default;
$layout-lg-width: ($page-width - 1) !default;

$layout-sm-height: (480px - 1) !default;
$layout-md-height: (768px - 1) !default;
$layout-lg-height: (1024px - 1) !default;

$grid-breakpoint: $layout-sm-width !default;