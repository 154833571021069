.news-detail {
  &__media {
    img { display: block; }
  }
  &__header {
    margin: $gap*2 0;
  }
  &__body {
    font-size: 1.2em;
    line-height: 1.5;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: $gap*4;

    .news-backlink-wrap {
      display: none;
    }
  }
  &__date,
  &__next {
    font-weight: 600;
    color: $dark-grey-color;
  }
  &__next {
    $arrow-size: 0.5em;
    position: relative;
    left: -$arrow-size*3;
    @include caret($arrow-size) {
      border-width: 2px;
      margin-left: $arrow-size;
      transform: translateY($arrow-size) rotate(135deg);
    };
  }
}
