// general project styling
@include font-face('Source Sans Pro', 'SourceSansPro-Light', 300);
@include font-face('Source Sans Pro', 'SourceSansPro-LightItalic', 300, italic);
@include font-face('Source Sans Pro', 'SourceSansPro-Regular', normal);
@include font-face('Source Sans Pro', 'SourceSansPro-Italic', normal, italic);
@include font-face('Source Sans Pro', 'SourceSansPro-SemiBold', 600);
@include font-face('Source Sans Pro', 'SourceSansPro-SemiBoldItalic', 600, italic);
@include font-face('Source Sans Pro', 'SourceSansPro-Bold', bold);
@include font-face('Source Sans Pro', 'SourceSansPro-BoldItalic', bold, italic);

*:focus {
  outline-style: none;
  box-shadow: 0px 1px $grey-color;
}

body {
  overflow-x: hidden;
}

h1 { font-size: 4em }
h2 { font-size: 3em }
h3 { font-size: 2em }
h4 { font-size: 1.5em }
h5,
h6 { font-size: 1.0em }

@media (max-width: $small-desktop-width) {
  h1 { font-size: 3em }
  h2 { font-size: 2em }
  h3 { font-size: 1.5em }
  h4 { font-size: 1.2em }
  h5,
  h6 { font-size: 1.0em }
}

@include media(mobile) {
  h1 { font-size: 2.5em }
  h2 { font-size: 1.75em }
}

a {
  transition:
  color .15s ease,
  background-color .15s ease;
  &:hover {
    text-decoration: none;
  }
}

ul:not([class]) {
  list-style-type: square;
  padding: 0;
  margin-left: 1.25em;

  li {
    ul {
      list-style-type: none;
      color: $grey-color;
      margin-left: 0;

      li {
        &:before {
          content: "–";
          text-indent: -5px;
          margin-left: -1.25em;
          margin-right: 0.75em;
        }
      }
    }
  }
}

table {
  position: relative;
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  margin-top: $gap*2;
  margin-bottom: $gap*2;

  th, td {
    padding: $gap $gap*2;
    font-weight: normal;
    text-align: left;
  }
  thead {
    @include text-color($bg-color);
    th {
      text-align: left;
      background: $cobalt-color;

      &[colspan] {
        padding-left: $gap*2.5;

        &:before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          height: 100%;
          width: $gap/2;
          background: $bg-color;
          transform: translateX(-$gap*2.5);
          z-index: 1;
        }
      }
    }
  }

  tfoot {
    display: table-row-group;
    th {
      background: $cool-blue-color;
      color: $bg-color;

      &[data-style="light"] {
        background: lighten($cool-blue-color, 10%);
      }
    }
  }
  tbody {
    background: $very-light-blue-color;

    tr {
      transition: background-color .15s ease;
      background: transparent;

      &:nth-child(even) {
        background: transparentize($text-color, 0.9);
      }
      &:hover {
        background: transparentize($text-color, 0.8);
      }

      td {
        &[data-style="light"] {
          background: transparentize($bg-color, 0.7);
        }

        &[data-mobile] {
          display: none;
        }
      }
    }
  }

  @include media(mobile) {
    thead, tfoot {
      display: none;
    }


    tbody {
      background: none;

      > tr {
        background: $very-light-blue-color;
        display: block;

        + tr {
          margin-top: $gap*3;
        }
      }
      tr {
        td {
          display: none;

          &[data-mobile] {
            display: block;
            padding: 0;

            table {
              margin: -$gap*2 0;

              tr:nth-child(even) {
                background: transparentize($text-color, 0.9);
              }

              tbody {
                tr {
                  display: table-row;
                  td {
                    display: table-cell;
                    &[data-style="blue"] {
                      background: $cobalt-color;
                      color: $bg-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

