.products {
  &__traits {
    padding: $gap $gap*1.5;
    background: lighten($very-light-blue-color, 5%);
  }
  &__list {
    @include reset-list();
  }
  &__item {
    position: relative;
    padding: $gap*2;
    background: $very-light-blue-color;

    &:nth-child(even) {
      background: darken($very-light-blue-color, 5%);
    }
  }
  &__aside {
    display: flex;
    justify-content: flex-end;
  }
  &__image {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    max-width: 100%;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  &__labels {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: $gap;
    margin-right: -$gap;
    margin-top: -$gap*0.75;
  }
  &__info {
    margin-top: $gap*-3;
    &--link {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
}
