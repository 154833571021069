.main-nav {
  $debounce-time: .4s;

  @mixin menu-items-animation($delay: 0.05s) {
    transition: .2s;
    transition-delay: $delay;

    @for $i from 1 through 20 {
      &:nth-of-type(#{$i}) {
        transition-delay: calc(#{$delay} + 0.05s * #{$i});
      }
    }
  }

  &__list {
    text-transform: uppercase;
    @include reset-list();
  }

  &__item {
    &--level-0 {
      > a,
      > span {
        font-size: 1.2em;
        font-weight: bold;
        color: $text-color;
        text-decoration: none;
        padding: 0 1.3em;
        transition: 0.2s;
        &:after {
          display: none;
          .header:hover & {
            display: block;
          }
        }
        &:hover { color: $faudi-color}
      }
    }

    &--level-1 {
      a, span {
        opacity: .6;        
        color: $bg-color;
        transition: .2s;
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  &__drop {
    background: $faudi-color;
  }

  @include media(desktop) {
    width: auto;
    margin-right: $gap*4;

    &__drop {
      display: none;
      position: absolute;
      left: 0;
      top: var(--header-height);
      width: 100%;
      padding: $gap;
      box-sizing: border-box;

      .header:hover & {
        display: block;
      }
    }

    &__list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      &--level-1 {
        justify-content: flex-start;
        @include center();
        @include width-range();
      }
    }

    &__item {
      &--level-0 {
        &.main-nav__item--parent {

          & > a,
          & > span {
            position: relative;
            // add corner for dropdown to the bottom of the nav item
            @include corner(1.4em, after) {
              left: 50%;
              top: calc(var(--header-height)/2 - 1px);
              transform: translate(-50%, 0) rotate(45deg);
              color: $faudi-color;
              opacity: 0;
              transition: $debounce-time ease;
              transition-delay: $debounce-time/2;
            }
            &:hover { color: $faudi-color}
          }

          &:hover {
            z-index: 2;

            & > a,
            & > span {
              cursor: pointer;
              
              // add pseudo element to "connect" item with dropdown when hovering
              &:before {
                display: block;
                position: absolute;
                content: "";
                top: 1.25em;
                transform: skewY(-5deg);
                right: 0;
                width: 200%;
                height: calc(var(--header-height));
              }

              &:after {
                opacity: 1;
                transition: opacity $debounce-time/2 ease-in;
              }

              & ~ .main-nav__drop {
                transition: $debounce-time;
                transition-delay: $debounce-time/2 ease;
                z-index: 2;
                //animation: nav-bar-in $debounce-time ease-in;

                .main-nav__item--level-1 {
                  opacity: 1;
                  transform: translateX(0);
                  @include menu-items-animation($debounce-time);
                }
              }
            }
          }
        }

        &:not(:hover) {

          & > a,
          & > span {
            &:after {
              opacity: 0;
              transition: $debounce-time/2;
              transition-delay: $debounce-time/2;
            }
          }

          .main-nav__drop {
            visibility: visible;
            opacity: 0;
            transition: $debounce-time;
            transition-delay: $debounce-time;
            z-index: 1;

            .main-nav__item--level-1 {
              transform: translateX(3em);
              opacity: 0;
              transition: $debounce-time;
              transition-delay: $debounce-time;
            }
          }
        }

        &.main-nav__item--active {
          > a,
          & > span { color: $darker-grey-color; }
        }

        &:first-of-type {
          &.main-nav__item--parent:hover > a {
            // make the first pseudo element larger for first item to compensate for mouse path
            &:before {
              right: 0;
              left: auto;
              top: 0;
              transform: none;
              width: calc(50vw - 10em + 100%); // 10em = logo-width
              height: calc(var(--header-height)*.75);
            }
          }
        }

      }
      &--level-1 {
        transform: translateX(3em);
        opacity: 0;
        margin: $gap;
        text-align: center;

        &.main-nav__item--active {
          > a,
          > span { opacity: 1; }
        }
      }
    }
  }

  @include media(tablet) {
    width: 80vw;
    max-width: 350px;
    font-size: .85em;
    letter-spacing: .075em;

    &__list {
      &--level-0 {
        border-bottom: 2px $cool-blue-color solid;
      }
      &--level-1 {
        padding: $gap/2 0;
      }
    }
    &__item {
      position: relative;

      > a,
      > span {
        display: block;
        position: relative;
        z-index: 1;
        font-weight: normal;
        color: $bg-color;
      }
      &--level-0 {
        border-top: 2px $cool-blue-color solid;
        > a,
        > span { 
          padding: $gap/2 $gap*2 $gap/2 $gap;
          z-index: 3;

          &:hover {
            color: $bg-color;
            opacity: 0.6;
            cursor: pointer;
          }
        }
      }
      &--level-1 {
        animation: nav-item-in .3s ease both;

        @for $i from 1 through 20 {
          &:nth-of-type(#{$i}) {
            animation-delay: calc(0.05s * #{$i});
          }
        }

        font-size: .95em;
        > a { padding: $gap/4 $gap; }
      }
      &--active {
        background: $cobalt-color;
      }
    }

    &__drop {
      z-index: 0;
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateY(-100%);
        width: 100%;
        height: 2.5rem;
        background: $cobalt-color;
        z-index: -1;
      }
    }
  }
}

@keyframes nav-item-in {
  from {
    opacity: 0;
    transform: translateX(3em);
  }
}

@keyframes nav-bar-in {
  from {
    visibility: hidden;
    transform: translateY(-1em);
  }
}

@keyframes arrow-in {
  from {
    visibility: hidden;
    transform: translate(-50%, -1em) rotate(45deg);
  }
  to {
    visibility: visible;
    transform: translate(-50%, 0) rotate(45deg);
  }
}
